const actions = {
  GET_TAGS_REQUEST: 'GET_TAGS_REQUEST',
  GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS',
  GET_TAGS_ERROR: 'GET_TAGS_ERROR',
  FETCHING_TAGS: 'FETCHING_TAGS',
  GET_PATIENT_TAGS_REQUEST: 'GET_PATIENT_TAGS_REQUEST',
  GET_PATIENT_TAGS_SUCCESS: 'GET_PATIENT_TAGS_SUCCESS',
  GET_PATIENT_TAGS_ERROR: 'GET_PATIENT_TAGS_ERROR',
  FETCHING_PATIENT_TAGS: 'FETCHING_PATIENT_TAGS',
  SET_PATIENT_TAGS_REQUEST: 'SET_PATIENT_TAGS_REQUEST',
  SET_PATIENT_TAGS_SUCCESS: 'SET_PATIENT_TAGS_SUCCESS',
  SET_PATIENT_TAGS_ERROR: 'SET_PATIENT_TAGS_ERROR',
  CREATE_TAG_REQUEST: 'CREATE_TAG_REQUEST',
  CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
  CREATE_TAG_ERROR: 'CREATE_TAG_ERROR',
  CREATING_TAG: 'CREATING_TAG',
  UPDATE_TAG_REQUEST: 'UPDATE_TAG_REQUEST',
  UPDATE_TAG_SUCCESS: 'UPDATE_TAG_SUCCESS',
  UPDATE_TAG_ERROR: 'UPDATE_TAG_ERROR',
  // QUERYING_TAGS: 'QUERYING_TAGS',
  // QUERY_PARAM_TAG_REQUEST: 'QUERY_PARAM_TAG_REQUEST',
  // QUERY_PARAM_TAG_SUCCESS: 'QUERY_PARAM_TAG_SUCCESS',
  // QUERY_PARAM_TAG_ERROR: 'QUERY_PARAM_TAG_ERROR',
  // RESET_PARAM_QUERY: 'RESET_PARAM_QUERY',
  getTags: () => ({
    type: actions.GET_TAGS_REQUEST,
  }),
  getPatientTags: () => ({
    type: actions.GET_PATIENT_TAGS_REQUEST,
  }),
  setPatientTags: (tags) => ({
    type: actions.SET_PATIENT_TAGS_REQUEST,
    payload: {
      tags,
    },
  }),
  createTag: (name, color) => ({
    type: actions.CREATE_TAG_REQUEST,
    payload: {
      name,
      color,
    },
  }),
  updateTag: (name, color, id) => ({
    type: actions.UPDATE_TAG_REQUEST,
    payload: {
      name,
      color,
      id,
    },
  }),
  // queryParamTag: (tag, mode, value, pagination = null) => ({
  //   type: actions.QUERY_PARAM_TAG_REQUEST,
  //   payload: {
  //     tag,
  //     mode,
  //     value,
  //     pagination,
  //   },
  // }),
  // resetParamQuery: () => ({
  //   type: actions.RESET_PARAM_QUERY,
  // }),
};

export default actions;
