const actions = {
  GOOGLE_ANALYTICS_REQUEST: 'GOOGLE_ANALYTICS_REQUEST',
  GOOGLE_ANALYTICS_SUCCESS: 'GOOGLE_ANALYTICS_SUCCESS',
  FETCH_USER_DATA_REQUEST: 'FETCH_USER_DATA_REQUEST',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_REFERRAL_LIST_REQUEST: 'FETCH_REFERRAL_LIST_REQUEST',
  FETCH_REFERRAL_LIST_SUCCESS: 'FETCH_REFERRAL_LIST_SUCCESS',
  FETCH_REFERRAL_LIST_ERROR: 'FETCH_REFERRAL_LIST_ERROR',
  FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_REQUEST: 'FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_REQUEST',
  FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_SUCCESS: 'FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_SUCCESS',
  FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_ERROR: 'FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_ERROR',
  GET_RANGE_APPOINTMENTS_REQUEST: 'GET_RANGE_APPOINTMENTS_REQUEST',
  GET_RANGE_APPOINTMENTS_SUCCESS: 'GET_RANGE_APPOINTMENTS_SUCCESS',
  GET_RANGE_APPOINTMENTS_ERROR: 'GET_RANGE_APPOINTMENTS_ERROR',
  FETCHING_APPOINTMENTS: 'FETCHING_APPOINTMENTS',
  VERIFY_PATIENTS_QUERY_REQUEST: 'VERIFY_PATIENTS_QUERY_REQUEST',
  SET_QUERY_PATIENTS_ANALYTICS_STATUS: 'SET_QUERY_PATIENTS_ANALYTICS_STATUS',
  SET_QUERY_PATIENTS_VERSION: 'SET_QUERY_PATIENTS_VERSION',
  SET_QUERY_PATIENTS_COUNT: 'SET_QUERY_PATIENTS_COUNT',
  SET_QUERY_PATIENTS_ANALYTICS_COUNT: 'SET_QUERY_PATIENTS_ANALYTICS_COUNT',
  QUERY_PATIENTS_REQUEST: 'QUERY_PATIENTS_REQUEST',
  QUERY_PATIENTS_SUCCESS: 'QUERY_PATIENTS_SUCCESS',
  QUERY_PATIENTS_ERROR: 'QUERY_PATIENTS_ERROR',
  QUERYING_PATIENTS: 'QUERYING_PATIENTS',
  RESET_PATIENTS_QUERY: 'RESET_PATIENTS_QUERY',
  GET_QUERY_PATIENTS_ANALYTICS_INFO_REQUEST: 'GET_QUERY_PATIENTS_ANALYTICS_INFO_REQUEST',
  GET_QUERY_PATIENTS_ANALYTICS_INFO_SUCCESS: 'GET_QUERY_PATIENTS_ANALYTICS_INFO_SUCCESS',
  GET_QUERY_PATIENTS_ANALYTICS_INFO_ERROR: 'GET_QUERY_PATIENTS_ANALYTICS_INFO_ERROR',
  SET_CITIES_OPTIONS: 'SET_CITIES_OPTIONS',
  SET_STATES_OPTIONS: 'SET_STATES_OPTIONS',
  GENERATE_CSV_REQUEST: 'GENERATE_CSV_REQUEST',
  GENERATE_CSV_SUCCESS: 'GENERATE_CSV_SUCCESS',
  GENERATE_CSV_ERROR: 'GENERATE_CSV_ERROR',
  GET_PATIENTS_CSV_LIST_REQUEST: 'GET_PATIENTS_CSV_LIST_REQUEST',
  GET_PATIENTS_CSV_LIST_SUCCESS: 'GET_PATIENTS_CSV_LIST_SUCCESS',
  GET_PATIENTS_CSV_LIST_ERROR: 'GET_PATIENTS_CSV_LIST_ERROR',
  GET_TAGS_PARAM_CSV_LIST_REQUEST: 'GET_TAGS_PARAM_CSV_LIST_REQUEST',
  GET_TAGS_PARAM_CSV_LIST_SUCCESS: 'GET_TAGS_PARAM_CSV_LIST_SUCCESS',
  GET_TAGS_PARAM_CSV_LIST_ERROR: 'GET_TAGS_PARAM_CSV_LIST_ERROR',
  DOWNLOAD_CSV_REQUEST: 'DOWNLOAD_CSV_REQUEST',
  DOWNLOAD_CSV_ERROR: 'DOWNLOAD_CSV_ERROR',
  QUERYING_TAGS: 'QUERYING_TAGS',
  QUERY_PARAM_TAG_REQUEST: 'QUERY_PARAM_TAG_REQUEST',
  QUERY_PARAM_TAG_SUCCESS: 'QUERY_PARAM_TAG_SUCCESS',
  QUERY_PARAM_TAG_ERROR: 'QUERY_PARAM_TAG_ERROR',
  RESET_PARAM_QUERY: 'RESET_PARAM_QUERY',
  SET_STATUS_WAS_SET_TO_AWAITING: 'SET_STATUS_WAS_SET_TO_AWAITING',
  GET_TOTAL_PATIENTS_REQUEST: 'GET_TOTAL_PATIENTS_REQUEST',
  GET_TOTAL_PATIENTS_SUCCESS: 'GET_TOTAL_PATIENTS_SUCCESS',
  GET_TOTAL_PATIENTS_ERROR: 'GET_TOTAL_PATIENTS_ERROR',
  GET_GENDER_COUNT_REQUEST: 'GET_GENDER_COUNT_REQUEST',
  GET_GENDER_COUNT_SUCCESS: 'GET_GENDER_COUNT_SUCCESS',
  GET_GENDER_COUNT_ERROR: 'GET_GENDER_COUNT_ERROR',
  GET_AGE_COUNT_REQUEST: 'GET_AGE_COUNT_REQUEST',
  GET_AGE_COUNT_SUCCESS: 'GET_AGE_COUNT_SUCCESS',
  GET_AGE_COUNT_ERROR: 'GET_AGE_COUNT_ERROR',
  analyticsRequest: () => ({
    type: actions.GOOGLE_ANALYTICS_REQUEST,
  }),
  fetchUserDataRequest: (userId) => ({
    type: actions.FETCH_USER_DATA_REQUEST,
    payload: userId,
  }),
  getReferalList: () => ({
    type: actions.FETCH_REFERRAL_LIST_REQUEST,
  }),
  getRequestingDoctorList: () => ({
    type: actions.FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_REQUEST,
  }),
  getRangeAppointments: (start, end) => ({
    type: actions.GET_RANGE_APPOINTMENTS_REQUEST,
    payload: {
      start,
      end,
    },
  }),
  verifyPatientsQuery: () => ({
    type: actions.VERIFY_PATIENTS_QUERY_REQUEST,
  }),
  queryPatients: (filters = {}, pagination = null) => ({
    type: actions.QUERY_PATIENTS_REQUEST,
    payload: {
      filters,
      pagination,
    },
  }),
  resetPatientsQuery: () => ({
    type: actions.RESET_PATIENTS_QUERY,
  }),
  queryParamTag: (tag, queryItems, pagination = null) => ({
    type: actions.QUERY_PARAM_TAG_REQUEST,
    payload: {
      tag,
      queryItems,
      pagination,
    },
  }),
  resetParamQuery: () => ({
    type: actions.RESET_PARAM_QUERY,
  }),
  getQueryPatientsAnalyticsInfo: () => ({
    type: actions.GET_QUERY_PATIENTS_ANALYTICS_INFO_REQUEST,
  }),
  generateCsv: (mode, args, selectedId = null) => ({
    type: actions.GENERATE_CSV_REQUEST,
    payload: {
      mode,
      args,
      selectedId,
    },
  }),
  getPatientsCsv: () => ({
    type: actions.GET_PATIENTS_CSV_LIST_REQUEST,
  }),
  getTagsParamCsv: () => ({
    type: actions.GET_TAGS_PARAM_CSV_LIST_REQUEST,
  }),
  downloadCsv: (id, mode) => ({
    type: actions.DOWNLOAD_CSV_REQUEST,
    payload: {
      id,
      mode,
    },
  }),
  getTotalPatients: () => ({
    type: actions.GET_TOTAL_PATIENTS_REQUEST,
  }),
  getGenderCount: () => ({
    type: actions.GET_GENDER_COUNT_REQUEST,
  }),
  getAgeCount: () => ({
    type: actions.GET_AGE_COUNT_REQUEST,
  }),
};
export default actions;
