import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  update,
  push,
  get,
  child,
} from 'firebase/database';
import {
  all,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  select,
  take,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import actions from './actions';
import authActions from '../auth/actions';
import appActions from '../app/actions';

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getIsProfessionalFromStore = (state) => state.Auth.professional;

const getSeectedIdFromStore = (state) => state.Contacts.seectedId;

function createTagsListener(mainUser, unified = {}, addressUid) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    let tagRef = null;
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      tagRef = ref(db, `unified/${unified.id}/tags/items`);
    } else {
      tagRef = ref(db, `users/${uid}/tags/items`);
    }
    const unsubscribe = onValue(tagRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getTagsRequest() {
  yield takeLatest([
    authActions.LOGIN_SUCCESS,
    // actions.GET_TAGS_REQUEST,
  ], function* () {
    try {
      const professional = yield select(getIsProfessionalFromStore);
      if (professional) {
        yield put({ type: actions.FETCHING_TAGS });
        const mainUser = yield select(getMainUserFromStore);
        const unified = yield select(getUnifiedTokenStore);
        let addressUid = yield select(getSelectedAddressFromStore);
        if (!addressUid) {
          yield take(appActions.SELECT_ADDRESS);
          addressUid = yield select(getSelectedAddressFromStore);
        }
        const tagsListener = yield call(createTagsListener, mainUser, unified, addressUid);
        yield takeEvery(tagsListener, function* (tags) {
          yield put({
            type: actions.GET_TAGS_SUCCESS,
            payload: {
              tags,
            },
          });
        });
        const actionTriggered = yield take([
          appActions.CANCEL_LISTENERS,
        ]);
        tagsListener.close();
        if (actionTriggered?.type === 'CANCEL_LISTENERS') {
          // It is the general "cancel all listeners".
          yield put({
            type: appActions.CANCEL_LISTENERS_SUCCESS,
          });
        }
        // const tags = yield call(getTagsFromDB, mainUser);
        // console.log(tags.val());
        // yield put({
        //   type: actions.GET_TAGS_SUCCESS,
        //   payload: {
        //     tags: tags.val(),
        //   },
        // });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_TAGS_ERROR,
      });
    }
  });
}

function getPatientTagsFromDB(seectedId, mainUser, unified = {}, addressUid) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `unified/${unified.id}/tags/patients/${seectedId}`));
  }
  return get(child(dbRef, `users/${uid}/tags/patients/${seectedId}`));
}

// function getTagsWithValueFromDB(tagId, seectedId, mainUser) {
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const auth = getAuth();
//     const { currentUser } = auth;
//     ({ uid } = currentUser);
//   }
//   const db = getDatabase();
//   const dbRef = ref(db);
//   return get(child(dbRef, `users/${uid}/tags/tagsWithValue/${tagId}/${seectedId}`));
// }

// function* getTagsValue(arrToSearch, seectedId, mainUser) {
//   return yield all(arrToSearch.map((key) => call(getTagsWithValueFromDB, key, mainUser)));
// }

export function* getPatientTagsRequest() {
  yield takeLatest(actions.GET_PATIENT_TAGS_REQUEST, function* () {
    try {
      yield put({ type: actions.FETCHING_PATIENT_TAGS });
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const tags = yield call(getPatientTagsFromDB, seectedId, mainUser, unified, addressUid);
      yield put({
        type: actions.GET_PATIENT_TAGS_SUCCESS,
        payload: {
          tags: tags.val(),
          seectedId,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_PATIENT_TAGS_ERROR,
      });
    }
  });
}

function savePatientTagsOnDB(seectedId, tags, mainUser, unified = {}, addressUid) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/tags/patients/${seectedId}`] = tags;
  } else {
    updates[`users/${uid}/tags/patients/${seectedId}`] = tags;
  }
  return update(dbRef, updates);
  // return true;
}

export function* setPatientTagsRequest() {
  yield takeLatest(actions.SET_PATIENT_TAGS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.FETCHING_PATIENT_TAGS });
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(savePatientTagsOnDB, seectedId, action.payload.tags, mainUser, unified, addressUid);
      yield all([
        put({ type: actions.GET_PATIENT_TAGS_REQUEST }),
        put({ type: actions.SET_PATIENT_TAGS_SUCCESS }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SET_PATIENT_TAGS_ERROR,
      });
    }
  });
}

function createTagOnDB({ name, color }, mainUser, unified = {}, addressUid) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const pushKey = push(child(ref(db), `unified/${unified.id}/tags/items`)).key;
    updates[`unified/${unified.id}/tags/items/${pushKey}`] = { name, color };
  } else {
    const pushKey = push(child(ref(db), `users/${uid}/tags/items`)).key;
    updates[`users/${uid}/tags/items/${pushKey}`] = { name, color };
  }
  return update(dbRef, updates);
}

export function* createTagRequest() {
  yield takeLatest(actions.CREATE_TAG_REQUEST, function* (action) {
    try {
      yield put({ type: actions.CREATING_TAG });
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(createTagOnDB, action.payload, mainUser, unified, addressUid);
      yield all([
        // put({ type: actions.GET_TAGS_REQUEST }),
        put({ type: actions.CREATE_TAG_SUCCESS }),
      ]);
      // yield put({
      //   type: actions.CREATE_TAG_SUCCESS,
      // });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_TAG_ERROR,
      });
    }
  });
}

function updateTagOnDB({ name, color, id }, mainUser, unified = {}, addressUid) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/tags/items/${id}/name`] = name;
    updates[`unified/${unified.id}/tags/items/${id}/color`] = color;
  } else {
    updates[`users/${uid}/tags/items/${id}/name`] = name;
    updates[`users/${uid}/tags/items/${id}/color`] = color;
  }
  return update(dbRef, updates);
}

export function* updateTagRequest() {
  yield takeLatest(actions.UPDATE_TAG_REQUEST, function* (action) {
    try {
      yield put({ type: actions.CREATING_TAG });
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(updateTagOnDB, action.payload, mainUser, unified, addressUid);
      yield all([
        // put({ type: actions.GET_TAGS_REQUEST }),
        put({ type: actions.UPDATE_TAG_SUCCESS }),
      ]);
      // yield put({
      //   type: actions.CREATE_TAG_SUCCESS,
      // });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.UPDATE_TAG_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getTagsRequest),
    fork(getPatientTagsRequest),
    fork(setPatientTagsRequest),
    fork(createTagRequest),
    fork(updateTagRequest),
  ]);
}
