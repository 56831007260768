import _ from 'lodash';
import actions from './actions';

const initState = {
  newPatients: {},
  didReadMessage: null,
  savedAllChangesProp: {},
  patientAppointments: [],
};

export default function analyticsReducer(state = initState, action) {
  switch (action.type) {
    case actions.GOOGLE_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsData: action.payload,
      };
    case actions.FETCH_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        referralList: action.payload.referralList,
        referralListMonthly: action.payload.referralListMonthly,
      };
    case actions.FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        requestingDoctorList: action.payload.requestingDoctorList,
        requestingDoctorListMonthly: action.payload.requestingDoctorListMonthly,
      };
    case actions.GET_RANGE_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: _.cloneDeep(action.payload.appointments),
        fetchingAppointments: false,
      };
    case actions.GET_RANGE_APPOINTMENTS_ERROR:
      return {
        ...state,
        fetchingAppointments: null,
      };
    case actions.FETCHING_APPOINTMENTS:
      return {
        ...state,
        fetchingAppointments: true,
      };
    case actions.SET_QUERY_PATIENTS_ANALYTICS_STATUS:
      return {
        ...state,
        queryPatientsAnalyticsStatus: action.payload,
        checkedStatusOnce: true,
      };
    case actions.SET_QUERY_PATIENTS_VERSION:
      return {
        ...state,
        queryPatientsVersion: action.payload,
      };
    case actions.SET_QUERY_PATIENTS_COUNT:
      return {
        ...state,
        queryPatientsCount: action.payload,
      };
    case actions.QUERY_PATIENTS_SUCCESS:
      return {
        ...state,
        patientsList: action.payload.patientsList,
        noNextPage: action.payload.noNextPage,
        lastVisibleDoc: action.payload.lastVisibleDoc,
        lastFiltersSearched: action.payload.lastFiltersSearched,
        queryCount: action.payload.queryCount,
        queryIndex: action.payload.queryIndex,
        queryingPatients: false,
      };
    case actions.QUERY_PATIENTS_ERROR:
      return {
        ...state,
        queryingPatients: null,
      };
    case actions.QUERYING_PATIENTS:
      return {
        ...state,
        queryingPatients: true,
        // queryCount: null,
      };
    case actions.RESET_PATIENTS_QUERY:
      return {
        ...state,
        patientsList: undefined,
        noNextPage: undefined,
        lastVisibleDoc: undefined,
        lastFiltersSearched: undefined,
        queryCount: undefined,
        queryIndex: undefined,
      };
    case actions.QUERYING_TAGS:
      return {
        ...state,
        queryingTags: true,
        // queryCount: null,
      };
    case actions.QUERY_PARAM_TAG_SUCCESS:
      return {
        ...state,
        patientsListParam: action.payload.patientsList,
        noNextPageParam: action.payload.noNextPage,
        lastVisibleDocParam: action.payload.lastVisibleDoc,
        lastTagsSearchedParam: action.payload.lastTagsSearched,
        // lastModeSearchedParam: action.payload.lastModeSearched,
        // lastValueSearchedParam: action.payload.lastValueSearched,
        lastQueryItemSearched: action.payload.lastQueryItemSearched,
        queryCountParam: action.payload.queryCount,
        queryIndexParam: action.payload.queryIndex,
        queryingTags: false,
      };
    case actions.RESET_PARAM_QUERY:
      return {
        ...state,
        patientsListParam: undefined,
        noNextPageParam: undefined,
        lastVisibleDocParam: undefined,
        // lastTagsSearchedParam: undefined,
        // lastModeSearchedParam: undefined,
        lastQueryItemSearched: undefined,
        queryCountParam: undefined,
        queryIndexParam: undefined,
      };
    case actions.QUERY_PARAM_TAG_ERROR:
      return {
        ...state,
        queryingTags: null,
      };
    case actions.SET_CITIES_OPTIONS:
      return {
        ...state,
        citiesOptions: action.payload,
      };
    case actions.SET_STATES_OPTIONS:
      return {
        ...state,
        statesOptions: action.payload,
      };
    case actions.GET_PATIENTS_CSV_LIST_SUCCESS:
      return {
        ...state,
        patientsCsvList: action.payload,
      };
    case actions.GET_TAGS_PARAM_CSV_LIST_SUCCESS:
      return {
        ...state,
        tagsParamCsvList: action.payload,
      };
    case actions.SET_STATUS_WAS_SET_TO_AWAITING:
      return {
        ...state,
        statusWasSetToAwaiting: true,
      };
    case actions.GET_TOTAL_PATIENTS_SUCCESS:
      return {
        ...state,
        patientsCount: action.payload,
      };
    case actions.GET_GENDER_COUNT_SUCCESS:
      return {
        ...state,
        genderCount: action.payload,
      };
    case actions.GET_AGE_COUNT_SUCCESS:
      return {
        ...state,
        ageCount: action.payload.array,
      };
    default:
      return state;
  }
}
