// import _ from 'lodash';
import React, {
  // Component,
  useState,
  useEffect,
} from 'react';
// import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';
import { store, history, persistor } from './redux/store';
import PublicRoutes from './router';
import themes from './settings/themes';
import AppLocale from './languageProvider';
import config, {
  getCurrentLanguage,
} from './containers/LanguageSwitcher/config';
import { themeConfig } from './settings';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';
import ReloginModal from './components/reloginModal';
import PageLoading from './components/uielements/pageLoading';
// import appActions from './redux/app/actions';
// import entrancePersistActions from './redux/entrancePersist/actions';

import 'moment/locale/pt-br';

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

moment.updateLocale('pt-br', {
  months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_'),
  monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
  weekdays: 'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split('_'),
  weekdaysShort: 'dom_seg_ter_qua_qui_sex_sáb'.split('_'),
  weekdaysMin: 'dom_2ª_3ª_4ª_5ª_6ª_sáb'.split('_'),
});

function DashApp() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    Boot()
      .then(() => {
        console.info('Boot!');
        setLoaded(true);
      })
      .catch((error) => console.error(error));

    // Sentry.init({
    //   dsn: process.env.REACT_APP_SENTRY_DSN,
    //   integrations: [
    //     Sentry.browserTracingIntegration(),
    //     Sentry.replayIntegration(),
    //   ],
    //   // Performance Monitoring
    //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: [/^https:\/\/idoctor-dev.firebaseapp\.com/, /^https:\/\/meagenda\.com.br/],
    //   // Session Replay
    //   environment: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // });

    // this.persistor = persistStore(store, {}, () => {
    //   this.setState({ rehydrated: true });
    // });
  }, []);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  if (!loaded) {
    return null;
  }

  const onBeforeLift = () => {
    // take some action before the gate lifts
    // store.dispatch(entrancePersistActions.checkReduxPersistData());
  };

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <DashAppHolder>
            <Provider store={store}>
              <PersistGate
                loading={<PageLoading />}
                persistor={persistor}
                onBeforeLift={onBeforeLift}
              >
                <ReloginModal />
                <PublicRoutes history={history} />
              </PersistGate>
            </Provider>
          </DashAppHolder>
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

// class DashApp extends Component {
//   constructor() {
//     super();
//     this.state = {
//       // rehydrated: false,
//       loaded: false,
//     };
//     // this.persistor = null;
//   }

//   componentDidMount() {
//     Boot()
//       .then(() => {
//         console.log('Boot!');
//         this.setState({ loaded: true });
//       })
//       .catch((error) => console.error(error));
//     // this.persistor = persistStore(store, {}, () => {
//     //   this.setState({ rehydrated: true });
//     // });
//   }

//   render() {
//     const {
//       // rehydrated,
//       loaded,
//     } = this.state;
//     // if (!rehydrated || !loaded) {
//     //   return null;
//     // }
//     if (!loaded) {
//       return null;
//     }
//     // return (
//     //   <div style={{ position: 'absolute', backgroundColor: 'red', top: 0, left: 0, bottom: 0, right: 0 }}>
//     //     batata
//     //   </div>
//     // );
//     return (
//       <ConfigProvider locale={currentAppLocale.antd}>
//         <IntlProvider
//           locale={currentAppLocale.locale}
//           messages={currentAppLocale.messages}
//         >
//           <ThemeProvider theme={themes[themeConfig.theme]}>
//             <DashAppHolder>
//               <Provider store={store}>
//                 {/* <PersistGate loading={null} persistor={this.persistor}> */}
//                 <ReloginModal />
//                 <PublicRoutes history={history} />
//                 {/* </PersistGate> */}
//               </Provider>
//             </DashAppHolder>
//           </ThemeProvider>
//         </IntlProvider>
//       </ConfigProvider>
//     );
//   }
// }

export default DashApp;
export { AppLocale };
