import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  get,
  update,
  push,
  child,
  // startAfter,
  limitToFirst,
  query as dbQuery,
  orderByChild,
  startAt,
  // equalTo,
} from 'firebase/database';
import moment from 'moment-timezone';
import axios from 'axios';
import {
  all,
  takeEvery,
  fork,
  call,
  put,
  select,
  take,
  takeLatest,
  spawn,
} from 'redux-saga/effects';
import { notification } from '../../components';
import actions from './actions';
import appActions from '../app/actions';
import profileActions from '../profile/actions';
import { normalizeDelta, getTagsFromDelta } from '../../helpers/utility';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSeectedIdFromStore = (state) => state.Contacts.seectedId;

const getProfileFromStore = (state) => state.Profile.profile;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getCertificatesFromStore = (state) => state.Certificates.certificates;

const getLastCertificateFromStore = (state) => state.Certificates.lastCertificate;

const getMedicalReportsFromStore = (state) => state.Certificates.medicalReports;

const getLastMedicalReportFromStore = (state) => state.Certificates.lastMedicalReport;

const getOtherDocumentsFromStore = (state) => state.Certificates.otherDocuments;

const getLastOtherDocumentFromStore = (state) => state.Certificates.lastOtherDocument;

const getCustomUserFromStore = (state) => state.CustomUsers.customUsers;

const getTagsFromStore = (state) => state.Tags.tags;

// const getUnformattedCertificateTextFromStore = (state) => state.Certificates.newUnformattedCertificate;

// const getUnformattedMedicalReportTextFromStore = (state) => state.Certificates.newUnformattedMedicalReport;

// const getUnformattedOtherDocumentTextFromStore = (state) => state.Certificates.newUnformattedOtherDocument;

function getCertificatesFromDB(seectedId, addressUid, unified = {}, mainUser, lastItem) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    if (lastItem) {
      const databaseRef = ref(db, `unified/${unified.id}/certificates/${seectedId}`);
      return get(dbQuery(
        databaseRef,
        orderByChild('queryTimestamp'),
        // startAfter(lastItem.queryTimestamp, lastItem.id),
        // limitToFirst(5),
        startAt(lastItem.queryTimestamp, lastItem.id),
        limitToFirst(6),
      ));
      // return db.ref(`unified/${unified.id}/certificates/${seectedId}`)
      //   .orderByChild('queryTimestamp')
      //   .startAfter(lastItem.queryTimestamp, lastItem.id)
      //   .limitToFirst(5)
      //   .once('value');
    }
    const databaseRef = ref(db, `/unified/${unified.id}/certificates/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      limitToFirst(5),
    ));
    // return db.ref(`/unified/${unified.id}/certificates/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .limitToFirst(5)
    //   .once('value');
  }
  if (lastItem) {
    const databaseRef = ref(db, `users/${uid}/certificates/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      // startAfter(lastItem.queryTimestamp, lastItem.id),
      // limitToFirst(5),
      startAt(lastItem.queryTimestamp, lastItem.id),
      limitToFirst(6),
    ));
    // return db.ref(`users/${uid}/certificates/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .startAfter(lastItem.queryTimestamp, lastItem.id)
    //   .limitToFirst(5)
    //   .once('value');
  }
  const databaseRef = ref(db, `users/${uid}/certificates/${seectedId}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('queryTimestamp'),
    limitToFirst(5),
  ));
  // return db.ref(`users/${uid}/certificates/${seectedId}`)
  //   .orderByChild('queryTimestamp')
  //   .limitToFirst(5)
  //   .once('value');
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function convertBase64ToStorage(
  mode,
  patientId,
  recordId,
  index,
  image,
  idToken,
  addressUid,
  unified = {},
  mainUser,
) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let unifiedId = false;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    unifiedId = unified.id;
  }
  const bodyParameters = {
    uid,
    unifiedId,
    mode,
    patientId,
    recordId,
    index,
    image,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/convertBase64ToStorage`,
    bodyParameters,
    config,
  );
}

export function* getCertificates() {
  yield takeEvery([
    actions.GET_ALL_CERTIFICATES_REQUEST,
    actions.GET_CERTIFICATES_REQUEST,
  ], function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const lastCertificateFromStore = yield select(getLastCertificateFromStore);
      const currentCertificates = yield select(getCertificatesFromStore);
      let lastCertificatePagination = null;
      if (lastCertificateFromStore[seectedId] && action?.payload?.paginate) {
        lastCertificatePagination = lastCertificateFromStore[seectedId];
      }
      const certificates = yield call(
        getCertificatesFromDB,
        seectedId,
        addressUid,
        unified,
        mainUser,
        lastCertificatePagination,
      );
      const certificatesArray = [];
      certificates.forEach((el) => {
        certificatesArray.push({
          ...el.val(),
          id: el.key,
          key: el.val().timestamp || el.key,
        });
      });
      const lastCertificate = certificatesArray[certificatesArray.length - 1];

      const recordsLength = certificatesArray.length;
      const idToken = yield call(getIdToken);
      for (let i = 0; i < recordsLength; i += 1) {
        if (certificatesArray[i]?.text?.ops?.length > 0) {
          const arrLength = certificatesArray[i].text.ops.length;
          for (let j = 0; j < arrLength; j += 1) {
            if (certificatesArray[i].text.ops[j].insert?.image && certificatesArray[i].text.ops[j].insert?.image?.includes('data:image/')) {
              yield spawn(
                convertBase64ToStorage,
                'certificates',
                seectedId,
                certificatesArray[i].id,
                j,
                certificatesArray[i].text.ops[j].insert.image,
                idToken,
                addressUid,
                unified,
                mainUser,
              );
            }
          }
        }
      }

      if (lastCertificatePagination && currentCertificates[seectedId]) {
        yield put({
          type: actions.GET_CERTIFICATES_SUCCESS,
          payload: {
            certificates: { ...currentCertificates[seectedId], ...certificates.val() },
            id: seectedId,
            lastCertificate,
            noNextCertificatePage: certificatesArray.length < 5,
          },
        });
      } else {
        yield put({
          type: actions.GET_CERTIFICATES_SUCCESS,
          payload: {
            certificates: certificates.val(),
            id: seectedId,
            lastCertificate,
            noNextCertificatePage: certificatesArray.length < 5,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function getMedicalReportFromDB(seectedId, addressUid, unified = {}, mainUser, lastItem) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    if (lastItem) {
      const databaseRef = ref(db, `unified/${unified.id}/medicalReports/${seectedId}`);
      return get(dbQuery(
        databaseRef,
        orderByChild('queryTimestamp'),
        // startAfter(lastItem.queryTimestamp, lastItem.id),
        // limitToFirst(5),
        startAt(lastItem.queryTimestamp, lastItem.id),
        limitToFirst(6),
      ));
      // return db.ref(`unified/${unified.id}/medicalReports/${seectedId}`)
      //   .orderByChild('queryTimestamp')
      //   .startAfter(lastItem.queryTimestamp, lastItem.id)
      //   .limitToFirst(5)
      //   .once('value');
    }
    const databaseRef = ref(db, `/unified/${unified.id}/medicalReports/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      limitToFirst(5),
    ));
    // return db.ref(`/unified/${unified.id}/medicalReports/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .limitToFirst(5)
    //   .once('value');
  }
  if (lastItem) {
    const databaseRef = ref(db, `users/${uid}/medicalReports/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      // startAfter(lastItem.queryTimestamp, lastItem.id),
      // limitToFirst(5),
      startAt(lastItem.queryTimestamp, lastItem.id),
      limitToFirst(6),
    ));
    // return db.ref(`users/${uid}/medicalReports/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .startAfter(lastItem.queryTimestamp, lastItem.id)
    //   .limitToFirst(5)
    //   .once('value');
  }
  const databaseRef = ref(db, `users/${uid}/medicalReports/${seectedId}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('queryTimestamp'),
    limitToFirst(5),
  ));
  // return db.ref(`users/${uid}/medicalReports/${seectedId}`)
  //   .orderByChild('queryTimestamp')
  //   .limitToFirst(5)
  //   .once('value');
}

export function* getMedicalReports() {
  yield takeEvery([
    actions.GET_ALL_CERTIFICATES_REQUEST,
    actions.GET_MEDICAL_REPORTS_REQUEST,
  ], function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const lastMedicalReportFromStore = yield select(getLastMedicalReportFromStore);
      const currentMedicalReports = yield select(getMedicalReportsFromStore);
      let lastMedicalReportPagination = null;
      if (lastMedicalReportFromStore[seectedId] && action?.payload?.paginate) {
        lastMedicalReportPagination = lastMedicalReportFromStore[seectedId];
      }
      const medicalReports = yield call(
        getMedicalReportFromDB,
        seectedId,
        addressUid,
        unified,
        mainUser,
        lastMedicalReportPagination,
      );
      const medicalReportsArray = [];
      medicalReports.forEach((el) => {
        medicalReportsArray.push({
          ...el.val(),
          id: el.key,
          key: el.val().timestamp || el.key,
        });
      });
      const lastMedicalReport = medicalReportsArray[medicalReportsArray.length - 1];

      const recordsLength = medicalReportsArray.length;
      const idToken = yield call(getIdToken);
      for (let i = 0; i < recordsLength; i += 1) {
        if (medicalReportsArray[i]?.text?.ops?.length > 0) {
          const arrLength = medicalReportsArray[i].text.ops.length;
          for (let j = 0; j < arrLength; j += 1) {
            if (medicalReportsArray[i].text.ops[j].insert?.image && medicalReportsArray[i].text.ops[j].insert?.image?.includes('data:image/')) {
              yield spawn(
                convertBase64ToStorage,
                'medicalReports',
                seectedId,
                medicalReportsArray[i].id,
                j,
                medicalReportsArray[i].text.ops[j].insert.image,
                idToken,
                addressUid,
                unified,
                mainUser,
              );
            }
          }
        }
      }

      if (lastMedicalReportPagination && currentMedicalReports[seectedId]) {
        yield put({
          type: actions.GET_MEDICAL_REPORTS_SUCCESS,
          payload: {
            medicalReports: { ...currentMedicalReports[seectedId], ...medicalReports.val() },
            id: seectedId,
            lastMedicalReport,
            noNextMedicalReportPage: medicalReportsArray.length < 5,
          },
        });
      } else {
        yield put({
          type: actions.GET_MEDICAL_REPORTS_SUCCESS,
          payload: {
            medicalReports: medicalReports.val(),
            id: seectedId,
            lastMedicalReport,
            noNextMedicalReportPage: medicalReportsArray.length < 5,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function getOtherDocumentFromDB(seectedId, addressUid, unified = {}, mainUser, lastItem) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    if (lastItem) {
      const databaseRef = ref(db, `unified/${unified.id}/otherDocuments/${seectedId}`);
      return get(dbQuery(
        databaseRef,
        orderByChild('queryTimestamp'),
        // startAfter(lastItem.queryTimestamp, lastItem.id),
        // limitToFirst(5),
        startAt(lastItem.queryTimestamp, lastItem.id),
        limitToFirst(6),
      ));
      // return db.ref(`unified/${unified.id}/otherDocuments/${seectedId}`)
      //   .orderByChild('queryTimestamp')
      //   .startAfter(lastItem.queryTimestamp, lastItem.id)
      //   .limitToFirst(5)
      //   .once('value');
    }
    const databaseRef = ref(db, `/unified/${unified.id}/otherDocuments/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      limitToFirst(5),
    ));
    // return db.ref(`/unified/${unified.id}/otherDocuments/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .limitToFirst(5)
    //   .once('value');
  }
  if (lastItem) {
    const databaseRef = ref(db, `users/${uid}/otherDocuments/${seectedId}`);
    return get(dbQuery(
      databaseRef,
      orderByChild('queryTimestamp'),
      // startAfter(lastItem.queryTimestamp, lastItem.id),
      // limitToFirst(5),
      startAt(lastItem.queryTimestamp, lastItem.id),
      limitToFirst(6),
    ));
    // return db.ref(`users/${uid}/otherDocuments/${seectedId}`)
    //   .orderByChild('queryTimestamp')
    //   .startAfter(lastItem.queryTimestamp, lastItem.id)
    //   .limitToFirst(5)
    //   .once('value');
  }
  const databaseRef = ref(db, `users/${uid}/otherDocuments/${seectedId}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('queryTimestamp'),
    limitToFirst(5),
  ));
  // return db.ref(`users/${uid}/otherDocuments/${seectedId}`)
  //   .orderByChild('queryTimestamp')
  //   .limitToFirst(5)
  //   .once('value');
}

export function* getOtherDocuments() {
  yield takeEvery([
    actions.GET_ALL_CERTIFICATES_REQUEST,
    actions.GET_OTHER_DOCUMENTS_REQUEST,
  ], function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const lastOtherDocumentFromStore = yield select(getLastOtherDocumentFromStore);
      const currentOtherDocuments = yield select(getOtherDocumentsFromStore);
      let lastOtherDocumentPagination = null;
      if (lastOtherDocumentFromStore[seectedId] && action?.payload?.paginate) {
        lastOtherDocumentPagination = lastOtherDocumentFromStore[seectedId];
      }
      const otherDocuments = yield call(
        getOtherDocumentFromDB,
        seectedId,
        addressUid,
        unified,
        mainUser,
        lastOtherDocumentPagination,
      );
      const otherDocumentsArray = [];
      otherDocuments.forEach((el) => {
        otherDocumentsArray.push({
          ...el.val(),
          id: el.key,
          key: el.val().timestamp || el.key,
        });
      });
      const lastOtherDocument = otherDocumentsArray[otherDocumentsArray.length - 1];

      const recordsLength = otherDocumentsArray.length;
      const idToken = yield call(getIdToken);
      for (let i = 0; i < recordsLength; i += 1) {
        if (otherDocumentsArray[i]?.text?.ops?.length > 0) {
          const arrLength = otherDocumentsArray[i].text.ops.length;
          for (let j = 0; j < arrLength; j += 1) {
            if (otherDocumentsArray[i].text.ops[j].insert?.image && otherDocumentsArray[i].text.ops[j].insert?.image?.includes('data:image/')) {
              yield spawn(
                convertBase64ToStorage,
                'otherDocuments',
                seectedId,
                otherDocumentsArray[i].id,
                j,
                otherDocumentsArray[i].text.ops[j].insert.image,
                idToken,
                addressUid,
                unified,
                mainUser,
              );
            }
          }
        }
      }

      if (lastOtherDocumentPagination && currentOtherDocuments[seectedId]) {
        yield put({
          type: actions.GET_OTHER_DOCUMENTS_SUCCESS,
          payload: {
            otherDocuments: { ...currentOtherDocuments[seectedId], ...otherDocuments.val() },
            id: seectedId,
            lastOtherDocument,
            noNextOtherDocumentPage: otherDocumentsArray.length < 5,
          },
        });
      } else {
        yield put({
          type: actions.GET_OTHER_DOCUMENTS_SUCCESS,
          payload: {
            otherDocuments: otherDocuments.val(),
            id: seectedId,
            lastOtherDocument,
            noNextOtherDocumentPage: otherDocumentsArray.length < 5,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function saveCertificateOnDB(
  certificate,
  mode,
  pushKey,
  // newUnformattedCertificate,
  seectedId,
  timestamp,
  addressUid,
  unified = {},
  mainProfile = null,
  mainUser,
  customUsers,
  tagsToCreate,
  tagsToAdd,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  const auth = getAuth();
  const { currentUser } = auth;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const currentUid = currentUser.uid;
  const author = {};
  if (currentUid === mainProfile.id) {
    // It is the main account.
    author.firstName = mainProfile.firstName;
    author.lastName = mainProfile.lastName;
    author.uid = mainProfile.id;
  } else {
    // It is some of the 'customUsers' accounts.
    const foundProfile = customUsers.find((el) => el.id === currentUid);
    if (foundProfile) {
      author.firstName = foundProfile.firstName;
      author.lastName = foundProfile.lastName;
      author.uid = foundProfile.id;
      if (foundProfile.healthProfessional) {
        // It is a health professional account.
      } else {
        // Secretary account.
        author.notHealthProfessional = true;
      }
    }
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/${mode}s/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`unified/${unified.id}/${mode}s/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    updates[`unified/${unified.id}/${mode}s/${seectedId}/${pushKey}/text`] = normalizeDelta(certificate);
    // if (newUnformattedCertificate[seectedId]?.text) {
    //   updates[`unified/${unified.id}/${mode}s/${seectedId}/${timestamp}/unformattedText`] = newUnformattedCertificate[seectedId].text;
    // }
    if (!_.isEmpty(author)) {
      author.unified = true;
      updates[`unified/${unified.id}/${mode}s/${seectedId}/${pushKey}/professional`] = author;
    }
  } else {
    updates[`users/${uid}/${mode}s/${seectedId}/${pushKey}/timestamp`] = timestamp.format();
    updates[`users/${uid}/${mode}s/${seectedId}/${pushKey}/text`] = normalizeDelta(certificate);
    updates[`users/${uid}/${mode}s/${seectedId}/${pushKey}/queryTimestamp`] = -1 * parseInt(timestamp.format('x'), 10);
    // if (newUnformattedCertificate[seectedId]?.text) {
    //   updates[`users/${uid}/${mode}s/${seectedId}/${timestamp}/unformattedText`] = newUnformattedCertificate[seectedId].text;
    // }
    if (!_.isEmpty(author)) {
      updates[`users/${uid}/${mode}s/${seectedId}/${pushKey}/professional`] = author;
    }
  }
  tagsToCreate.forEach((tagName) => {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      const tagPushKey = push(child(ref(db), `unified/${unified.id}/tags/items`)).key;
      updates[`unified/${unified.id}/tags/items/${tagPushKey}`] = { name: tagName, color: '#1890ff' };
      updates[`unified/${unified.id}/tags/patients/${seectedId}/${tagPushKey}`] = true;
    } else {
      const tagPushKey = push(child(ref(db), `users/${uid}/tags/items`)).key;
      updates[`users/${uid}/tags/items/${tagPushKey}`] = { name: tagName, color: '#1890ff' };
      updates[`users/${uid}/tags/patients/${seectedId}/${tagPushKey}`] = true;
    }
  });
  tagsToAdd.forEach((tagKey) => {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      updates[`unified/${unified.id}/tags/patients/${seectedId}/${tagKey}`] = true;
    } else {
      updates[`users/${uid}/tags/patients/${seectedId}/${tagKey}`] = true;
    }
  });
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function generatePushKey(seectedId, addressUid, mode, unified = {}, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let pushKey = '';
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    pushKey = push(child(ref(db), `unified/${unified.id}/${mode}s/${seectedId}`)).key;
  } else {
    pushKey = push(child(ref(db), `users/${uid}/${mode}s/${seectedId}`)).key;
  }
  return pushKey;
}

export function* saveCertificate() {
  yield takeEvery(actions.SAVE_CERTIFICATE_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.CERTIFICATES_SECTION_START_UPDATE,
        payload: { mode: action.payload.mode },
      });
      yield call(sleep, 500);
      let profile = yield select(getProfileFromStore);
      if (_.isEmpty(profile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        profile = yield select(getProfileFromStore);
      }
      const timestamp = moment().tz('America/Sao_Paulo');
      const seectedId = yield select(getSeectedIdFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const customUsers = yield select(getCustomUserFromStore);
      // let newUnformattedCertificate = {};
      // if (action.payload.mode === 'certificate') {
      //   newUnformattedCertificate = yield select(getUnformattedCertificateTextFromStore);
      // }
      // if (action.payload.mode === 'medicalReport') {
      //   newUnformattedCertificate = yield select(getUnformattedMedicalReportTextFromStore);
      // }
      // if (action.payload.mode === 'otherDocument') {
      //   newUnformattedCertificate = yield select(getUnformattedOtherDocumentTextFromStore);
      // }
      const tagsInsideText = getTagsFromDelta(action.payload.text);
      const tagsFromStore = yield select(getTagsFromStore);
      const tagsToCreate = [];
      const tagsToAdd = [];
      tagsInsideText.forEach((el) => {
        const foundTag = Object.keys(tagsFromStore).find((tagId) => {
          if (tagsFromStore[tagId].name === el) {
            return true;
          }
          return false;
        });
        if (!foundTag) {
          // Need to create tag.
          tagsToCreate.push(el);
        } else {
          tagsToAdd.push(foundTag);
        }
      });
      const pushKey = generatePushKey(seectedId, addressUid, action.payload.mode, unified, mainUser);
      yield call(
        saveCertificateOnDB,
        action.payload.text,
        action.payload.mode,
        pushKey,
        // newUnformattedCertificate,
        seectedId,
        timestamp,
        addressUid,
        unified,
        profile,
        mainUser,
        customUsers,
        tagsToCreate,
        tagsToAdd,
      );
      if (action.payload.mode === 'certificate') {
        yield all([
          yield put({
            type: actions.GET_CERTIFICATES_REQUEST,
          }),
          yield put({
            type: actions.CHANGE_CERTIFICATE,
            payload: {
              certificate: {
                text: { ops: [] },
              },
              id: seectedId,
            },
          }),
          yield put({
            type: actions.CHANGE_UNFORMATTED_CERTIFICATE,
            payload: {
              certificate: {
                text: { ops: [] },
                files: [],
              },
              id: seectedId,
            },
          }),
          yield put({
            type: appActions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD,
            payload: {
              id: seectedId,
              field: 'certificate',
              fieldValue: true,
            },
          }),
        ]);
      }
      if (action.payload.mode === 'medicalReport') {
        yield all([
          yield put({
            type: actions.GET_MEDICAL_REPORTS_REQUEST,
          }),
          yield put({
            type: actions.CHANGE_MEDICAL_REPORT,
            payload: {
              medicalReport: {
                text: { ops: [] },
              },
              id: seectedId,
            },
          }),
          yield put({
            type: actions.CHANGE_UNFORMATTED_MEDICAL_REPORT,
            payload: {
              medicalReport: {
                text: { ops: [] },
                files: [],
              },
              id: seectedId,
            },
          }),
          yield put({
            type: appActions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD,
            payload: {
              id: seectedId,
              field: 'medicalReport',
              fieldValue: true,
            },
          }),
        ]);
      }
      if (action.payload.mode === 'otherDocument') {
        yield all([
          yield put({
            type: actions.GET_OTHER_DOCUMENTS_REQUEST,
          }),
          yield put({
            type: actions.CHANGE_OTHER_DOCUMENT,
            payload: {
              otherDocument: {
                text: { ops: [] },
              },
              id: seectedId,
            },
          }),
          yield put({
            type: actions.CHANGE_UNFORMATTED_OTHER_DOCUMENT,
            payload: {
              otherDocument: {
                text: { ops: [] },
                files: [],
              },
              id: seectedId,
            },
          }),
          yield put({
            type: appActions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD,
            payload: {
              id: seectedId,
              field: 'otherDocument',
              fieldValue: true,
            },
          }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      if (error?.message?.includes('contains a string greater than 10485760 utf8 bytes')) {
        notification(
          'error',
          'Não é possível adicionar imagens maiores que 10MB diretamente no editor de texto',
          'Utilize o método de "Anexar arquivo" logo abaixo do editor.',
        );
      } else {
        notification(
          'error',
          'Algo deu errado',
          'Tente novamente mais tarde.',
        );
      }
      yield put({
        type: actions.SAVE_CERTIFICATE_ERROR,
      });
    }
  });
}

function saveCertificateModelOnDB(certificate, mode, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/users/${uid}/${mode}Models`)).key;
  updates[`users/${uid}/${mode}Models/${pushKey}`] = certificate;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveCertificateModel() {
  yield takeEvery(actions.SAVE_CERTIFICATE_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_CERTIFICATE_MODEL,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        saveCertificateModelOnDB,
        action.payload.model,
        action.payload.mode,
        mainUser,
      );
      if (action.payload.mode === 'certificate') {
        yield put({
          type: actions.CERTIFICATE_MODELS_FETCH_REQUEST,
        });
      }
      if (action.payload.mode === 'medicalReport') {
        yield put({
          type: actions.MEDICAL_REPORT_MODELS_FETCH_REQUEST,
        });
      }
      if (action.payload.mode === 'otherDocument') {
        yield put({
          type: actions.OTHER_DOCUMENT_MODELS_FETCH_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_CERTIFICATE_MODEL_ERROR });
    }
  });
}

function getCertificateModelsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/certificateModels`));
}

export function* getCertificateModels() {
  yield takeEvery([
    actions.GET_ALL_CERTIFICATE_MODELS_REQUEST,
    actions.CERTIFICATE_MODELS_FETCH_REQUEST,
  ], function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const models = yield call(getCertificateModelsFromDB, mainUser);
      if (!models.val()) {
        const defaultModel = {
          ops: [
            {
              insert: 'Atesto para os devidos fins, que o(a) Sr(a) ',
            },
            {
              insert: {
                mention: {
                  denotationChar: '#',
                  id: 'patientName',
                  index: '0',
                  value: 'nome_do_paciente',
                },
              },
            },
            {
              insert: ', portador do CPF: ',
            },
            {
              insert: {
                mention: {
                  denotationChar: '#',
                  id: 'patientCpf',
                  index: '1',
                  value: 'cpf',
                },
              },
            },
            {
              insert: ' esteve sob meus cuidados no horário ___ do dia ___/___/___, necessitando de ___ dias de repouso.\nObservações:',
            },
          ],
        };
        yield call(
          saveCertificateModelOnDB,
          { name: 'Padrão', certificate: defaultModel },
          'certificate', // mode
          mainUser,
        );
        yield put({
          type: actions.CERTIFICATE_MODELS_FETCH_REQUEST,
        });
      } else {
        const editedModels = _.map(models.val(), (val, id) => (
          {
            ...val,
            id,
            certificate: val.certificate ? normalizeDelta(val.certificate) : { ops: [] },
          }
        ));
        yield put({
          type: actions.CERTIFICATE_MODELS_FETCH_SUCCESS,
          payload: {
            // models: editedModels,
            models: _.orderBy(editedModels, [(el) => el.name.toLowerCase()]),
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CERTIFICATE_MODELS_FETCH_ERROR,
      });
    }
  });
}

function getMedicalReportModelsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/medicalReportModels`));
}

export function* getMedicalReportModels() {
  yield takeEvery([
    actions.GET_ALL_CERTIFICATE_MODELS_REQUEST,
    actions.MEDICAL_REPORT_MODELS_FETCH_REQUEST,
  ], function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const models = yield call(getMedicalReportModelsFromDB, mainUser);
      let editedModels = [];
      if (models.val()) {
        editedModels = _.map(models.val(), (val, id) => (
          { ...val, id }
        ));
      }
      yield put({
        type: actions.MEDICAL_REPORT_MODELS_FETCH_SUCCESS,
        payload: {
          // models: editedModels,
          models: _.orderBy(editedModels, [(el) => el.name.toLowerCase()]),
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.MEDICAL_REPORT_MODELS_FETCH_ERROR,
      });
    }
  });
}

function getOtherDocumentModelsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/otherDocumentModels`));
}

export function* getOtherDocumentModels() {
  yield takeEvery([
    actions.GET_ALL_CERTIFICATE_MODELS_REQUEST,
    actions.OTHER_DOCUMENT_MODELS_FETCH_REQUEST,
  ], function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const models = yield call(getOtherDocumentModelsFromDB, mainUser);
      let editedModels = [];
      if (models.val()) {
        editedModels = _.map(models.val(), (val, id) => (
          { ...val, id }
        ));
      }
      yield put({
        type: actions.OTHER_DOCUMENT_MODELS_FETCH_SUCCESS,
        payload: {
          // models: editedModels,
          models: _.orderBy(editedModels, [(el) => el.name.toLowerCase()]),
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.OTHER_DOCUMENT_MODELS_FETCH_ERROR,
      });
    }
  });
}

function removeCertificateModelOnDB(prescriptionId, mode, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/${mode}Models/${prescriptionId}`] = null;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeCertificateModel() {
  yield takeEvery(actions.REMOVE_CERTIFICATE_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_REMOVING_CERTIFICATE_MODEL,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const { mode } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        removeCertificateModelOnDB,
        action.payload.id,
        action.payload.mode,
        mainUser,
      );
      if (mode === 'certificate') {
        yield put({
          type: actions.CERTIFICATE_MODELS_FETCH_REQUEST,
        });
      }
      if (mode === 'medicalReport') {
        yield put({
          type: actions.MEDICAL_REPORT_MODELS_FETCH_REQUEST,
        });
      }
      if (mode === 'otherDocument') {
        yield put({
          type: actions.OTHER_DOCUMENT_MODELS_FETCH_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.REMOVE_CERTIFICATE_MODEL_ERROR });
    }
  });
}

function updateCertificateModelOnDB({ model, id, mode }, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/${mode}Models/${id}`] = model;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateCertificateModel() {
  yield takeEvery(actions.UPDATE_CERTIFICATE_MODEL_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_CERTIFICATE_MODEL,
      });
      yield call(sleep, 500);
      const { mode } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      yield call(updateCertificateModelOnDB, action.payload, mainUser);
      if (mode === 'certificate') {
        yield put({
          type: actions.CERTIFICATE_MODELS_FETCH_REQUEST,
        });
      }
      if (mode === 'medicalReport') {
        yield put({
          type: actions.MEDICAL_REPORT_MODELS_FETCH_REQUEST,
        });
      }
      if (mode === 'otherDocument') {
        yield put({
          type: actions.OTHER_DOCUMENT_MODELS_FETCH_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_CERTIFICATE_MODEL_ERROR });
    }
  });
}

function saveCollapseCertificatesOnDB(
  value,
  pushKey,
  mode,
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/${mode}s/${seectedId}/${pushKey}/collapse`] = value;
  } else {
    updates[`users/${uid}/${mode}s/${seectedId}/${pushKey}/collapse`] = value;
  }
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* collapseCertificate() {
  yield takeLatest(actions.CHANGE_COLLAPSE_CERTIFICATE_REQUEST, function* (action) {
    try {
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(sleep, 3000);
      yield call(
        saveCollapseCertificatesOnDB,
        action.payload.value,
        action.payload.id, // pushKey
        action.payload.mode,
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
    } catch (error) {
      console.warn(error);
      notification('warning', 'Sua conexão com a internet está instável.');
    }
  });
}

function saveHideCertificateOnDB(
  value,
  pushKey,
  mode,
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/${mode}s/${seectedId}/${pushKey}/hidden`] = value;
  } else {
    updates[`users/${uid}/${mode}s/${seectedId}/${pushKey}/hidden`] = value;
  }
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* hideCertificate() {
  yield takeLatest(actions.CHANGE_HIDE_CERTIFICATE_REQUEST, function* (action) {
    try {
      if (action.payload.mode === 'certificate') {
        yield put({
          type: actions.CHANGING_HIDE_CERTIFICATE,
          payload: action.payload.item.id,
        });
      }
      if (action.payload.mode === 'medicalReport') {
        yield put({
          type: actions.CHANGING_HIDE_MEDICAL_REPORT,
          payload: action.payload.item.id,
        });
      }
      if (action.payload.mode === 'otherDocument') {
        yield put({
          type: actions.CHANGING_HIDE_OTHER_DOCUMENT,
          payload: action.payload.item.id,
        });
      }
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(
        saveHideCertificateOnDB,
        !action.payload.item.hidden,
        action.payload.item.id, // pushKey
        action.payload.mode,
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
      if (action.payload.mode === 'certificate') {
        yield put({
          type: actions.GET_CERTIFICATES_REQUEST,
        });
      }
      if (action.payload.mode === 'medicalReport') {
        yield put({
          type: actions.GET_MEDICAL_REPORTS_REQUEST,
        });
      }
      if (action.payload.mode === 'otherDocument') {
        yield put({
          type: actions.GET_OTHER_DOCUMENTS_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      notification('warning', 'Sua conexão com a internet está instável.');
      if (action.payload.mode === 'certificate') {
        yield put({ type: actions.CHANGE_HIDE_CERTIFICATE_ERROR });
      }
      if (action.payload.mode === 'medicalReport') {
        yield put({ type: actions.CHANGE_HIDE_MEDICAL_REPORT_ERROR });
      }
      if (action.payload.mode === 'otherDocument') {
        yield put({ type: actions.CHANGE_HIDE_OTHER_DOCUMENT_ERROR });
      }
    }
  });
}

export function* checkShowHiddenCertificatesStatus() {
  yield takeEvery(actions.GET_SHOW_HIDDEN_CERTIFICATES_STATUS, function* () {
    try {
      const dataCertificates = yield localStorage.getItem('show_hidden_certificates');
      if (_.isNull(dataCertificates)) {
        yield put({
          type: actions.GET_SHOW_HIDDEN_CERTIFICATES_STATUS_SUCCESS,
          value: false,
        });
      } else {
        yield put({
          type: actions.GET_SHOW_HIDDEN_CERTIFICATES_STATUS_SUCCESS,
          value: JSON.parse(dataCertificates),
        });
      }
      const dataMedicalReports = yield localStorage.getItem('show_hidden_medicalReports');
      if (_.isNull(dataMedicalReports)) {
        yield put({
          type: actions.GET_SHOW_HIDDEN_MEDICAL_REPORTS_STATUS_SUCCESS,
          value: false,
        });
      } else {
        yield put({
          type: actions.GET_SHOW_HIDDEN_MEDICAL_REPORTS_STATUS_SUCCESS,
          value: JSON.parse(dataMedicalReports),
        });
      }
      const dataOtherDocuments = yield localStorage.getItem('show_hidden_otherDocuments');
      if (_.isNull(dataOtherDocuments)) {
        yield put({
          type: actions.GET_SHOW_HIDDEN_OTHER_DOCUMENTS_STATUS_SUCCESS,
          value: false,
        });
      } else {
        yield put({
          type: actions.GET_SHOW_HIDDEN_OTHER_DOCUMENTS_STATUS_SUCCESS,
          value: JSON.parse(dataOtherDocuments),
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setShowHiddenCertificates() {
  yield takeEvery(actions.SET_SHOW_HIDDEN_CERTIFICATES, function* (action) {
    try {
      yield localStorage.setItem(`show_hidden_${action.payload.mode}`, action.payload.value);
      yield put({
        type: actions.GET_SHOW_HIDDEN_CERTIFICATES_STATUS,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCertificates),
    fork(saveCertificate),
    fork(getCertificateModels),
    fork(saveCertificateModel),
    fork(removeCertificateModel),
    fork(updateCertificateModel),
    fork(getMedicalReportModels),
    fork(getMedicalReports),
    fork(getOtherDocuments),
    fork(getOtherDocumentModels),
    fork(collapseCertificate),
    fork(hideCertificate),
    fork(checkShowHiddenCertificatesStatus),
    fork(setShowHiddenCertificates),
  ]);
}
