import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getFirestore,
  query as fsQuery,
  collection,
  where,
  limit,
  startAfter,
  getDocs,
  getCountFromServer,
  and,
  or,
} from 'firebase/firestore';
import {
  getDatabase,
  ref,
  query,
  onValue,
  orderByKey,
  orderByChild,
  startAt,
  endAt,
  limitToLast,
  get,
  child,
  push,
  update,
  query as dbQuery,
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from 'firebase/storage';
import moment from 'moment-timezone';
import { eventChannel } from 'redux-saga';
import axios from 'axios';
import {
  all,
  takeEvery,
  takeLatest,
  fork,
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import {
  rrulestr,
} from 'rrule';
import analyticsActions from './actions';
import agendaActions from '../agenda/actions';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getSelectedAgendaFromStore = (state) => state.Agenda.selectedAgenda;

const getFullBatchedAppointmentsArrFromStore = (state) => state.Agenda.fullBatchedAppointmentsArr;

const getPersistedBatchedAppointmentsFromStore = (state) => state.Agenda.persistedBatchedAppointments;

const getQueryPatientsAnalyticsVersionFromStore = (state) => state.Analytics.queryPatientsVersion;

const getQueryPatientsAnalyticsStatusFromStore = (state) => state.Analytics.queryPatientsAnalyticsStatus;

const getCheckedStatusOnceFromStore = (state) => state.Analytics.checkedStatusOnce;

const getPatientsListFromStoreFromStore = (state) => state.Analytics.patientsList;

const getLastVisibleDocFromStore = (state) => state.Analytics.lastVisibleDoc;

const getQueryCountFromStore = (state) => state.Analytics.queryCount;

const getQueryIndexFromStore = (state) => state.Analytics.queryIndex;

const getPatientsListParamFromStore = (state) => state.Analytics.patientsListParam;

const getLastVisibleDocParamFromStore = (state) => state.Analytics.lastVisibleDocParam;

const getQueryCountParamFromStore = (state) => state.Analytics.queryCountParam;

const getQueryIndexParamFromStore = (state) => state.Analytics.queryIndexParam;

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

// function retrieveAnalyticsData() {
//   return axios.get(`${ROOT_URL}/googleBigQuery`);
// }

// export function* requestsFetch() {
//   yield takeEvery(analyticsActions.GOOGLE_ANALYTICS_REQUEST, function* () {
//     try {
//       const data = yield call(retrieveAnalyticsData);
//       yield put({
//         type: analyticsActions.GOOGLE_ANALYTICS_SUCCESS,
//         payload: data.data,
//       });
//     } catch (error) {
//       console.warn(error);
//     }
//   });
// }

// function retrievePatientData() {
//   return axios.get(`${ROOT_URL}/getPatientsProfile`);
// }

// export function* fetchUserDataRequest() {
//   yield takeEvery(analyticsActions.FETCH_USER_DATA_REQUEST, function* () {
//     try {
//       const data = yield call(retrievePatientData);
//       yield put({
//         type: analyticsActions.FETCH_USER_DATA_SUCCESS,
//         payload: data.data,
//       });
//     } catch (error) {
//       console.warn(error);
//     }
//   });
// }

function fetchReferalListFromDB(mainUser, unified, currentAddress) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  if (unified.id && unified.address.some((a) => a === currentAddress)) {
    return get(child(dbRef, `unified/${unified.id}/referralList`));
  }
  return get(child(dbRef, `users/${uid}/referralList`));
}

function fetchReferralListMonthlyFromDB(addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const start = moment().tz('America/Sao_Paulo').subtract(1, 'year').format('YYYY-MM');
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(query(
      ref(db, `/unified/${unified.id}/referralListMonthly`),
      orderByKey(),
      startAt(start),
      limitToLast(12),
    ));
    // return new Promise((resolve) => {
    //   onValue(query(
    //     ref(db, `/unified/${unified.id}/referralListMonthly`),
    //     orderByKey(),
    //     startAt(start),
    //     limitToLast(12),
    //   ), resolve, { onlyOnce: true });
    // });
  }
  return get(query(
    ref(db, `/users/${uid}/referralListMonthly`),
    orderByKey(),
    startAt(start),
    limitToLast(12),
  ));
  // return new Promise((resolve) => {
  //   onValue(query(
  //     ref(db, `/users/${uid}/referralListMonthly`),
  //     orderByKey(),
  //     startAt(start),
  //     limitToLast(12),
  //   ), resolve, { onlyOnce: true });
  // });
}

export function* fetchReferalList() {
  yield takeEvery(analyticsActions.FETCH_REFERRAL_LIST_REQUEST, function* () {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const referralList = yield call(fetchReferalListFromDB, mainUser, unified, currentAddress);
      const referralListMonthly = yield call(fetchReferralListMonthlyFromDB, currentAddress, unified, mainUser);
      yield put({
        type: analyticsActions.FETCH_REFERRAL_LIST_SUCCESS,
        payload: {
          referralList: referralList.val(),
          referralListMonthly: referralListMonthly.val(),
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function fetchRequestingDoctorListFromDB(mainUser, unified, currentAddress) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  if (unified.id && unified.address.some((a) => a === currentAddress)) {
    return get(child(dbRef, `unified/${unified.id}/requestingDoctorList`));
  }
  return get(child(dbRef, `users/${uid}/requestingDoctorList`));
}

function fetchRequestingDoctorListMonthlyFromDB(addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const start = moment().tz('America/Sao_Paulo').subtract(1, 'year').format('YYYY-MM');
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(query(
      ref(db, `/unified/${unified.id}/requestingDoctorListMonthly`),
      orderByKey(),
      startAt(start),
      limitToLast(12),
    ));
  }
  return get(query(
    ref(db, `/users/${uid}/requestingDoctorListMonthly`),
    orderByKey(),
    startAt(start),
    limitToLast(12),
  ));
}

export function* fetchRequestingDoctorList() {
  yield takeEvery(analyticsActions.FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_REQUEST, function* () {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const requestingDoctorList = yield call(fetchRequestingDoctorListFromDB, mainUser, unified, currentAddress);
      const requestingDoctorListMonthly = yield call(fetchRequestingDoctorListMonthlyFromDB, currentAddress, unified, mainUser);
      yield put({
        type: analyticsActions.FETCH_ANALYTICS_REQUESTING_DOCTOR_LIST_SUCCESS,
        payload: {
          requestingDoctorList: requestingDoctorList.val(),
          requestingDoctorListMonthly: requestingDoctorListMonthly.val(),
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function fetchAppointmentsFromDB(agendaId, start, end, mode, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  // const start = moment().tz('America/Sao_Paulo').subtract(1, 'months').format('YYYY-MM-DD');
  return new Promise((resolve) => {
    onValue(query(
      ref(db, `/requests/${uid}/${agendaId}/${mode}`),
      orderByChild('time'),
      startAt(start),
      endAt(`${end}\uf8ff`),
    ), resolve, { onlyOnce: true });
  });
}

export function* getRangeAppointments() {
  yield takeLatest(analyticsActions.GET_RANGE_APPOINTMENTS_REQUEST, function* (action) {
    try {
      yield put({ type: analyticsActions.FETCHING_APPOINTMENTS });
      const mainUser = yield select(getMainUserFromStore);
      let agendaId = yield select(getSelectedAgendaFromStore);
      if (_.isEmpty(agendaId)) {
        yield take(agendaActions.SELECT_AGENDA);
        agendaId = yield select(getSelectedAgendaFromStore);
      }
      let fullBatchedAppointmentsArr = yield select(getFullBatchedAppointmentsArrFromStore);
      if (_.isUndefined(fullBatchedAppointmentsArr)) {
        yield take(agendaActions.SET_FULL_BATCHED_APPOINTMENTS_ARR);
        fullBatchedAppointmentsArr = yield select(getFullBatchedAppointmentsArrFromStore);
      }
      let persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      if (_.isUndefined(persistedBatchedAppointments)) {
        yield take(agendaActions.SET_PERSISTED_BATCHED_APPOINTMENTS);
        persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      }
      const { start, end } = action.payload;
      const [
        confirmed,
        canceled,
        pending,
        rejected,
        accepted,
      ] = yield all([
        call(fetchAppointmentsFromDB, agendaId, start, end, 'confirmed', mainUser),
        call(fetchAppointmentsFromDB, agendaId, start, end, 'canceled', mainUser),
        call(fetchAppointmentsFromDB, agendaId, start, end, 'pending', mainUser),
        call(fetchAppointmentsFromDB, agendaId, start, end, 'rejected', mainUser),
        call(fetchAppointmentsFromDB, agendaId, start, end, 'accepted', mainUser),
      ]);
      let analyticsConfirmed = [];
      let analyticsCanceled = [];
      let analyticsPending = [];
      let analyticsRejected = [];
      let analyticsAccepted = [];
      if (confirmed.val()) {
        analyticsConfirmed = _.map(confirmed.val(), (val, id) => {
          const { duration } = val;
          return {
            ...val,
            start: moment(val.time, 'YYYY/MM/DD HH:mm').toDate(),
            id,
            end: moment(val.time, 'YYYY/MM/DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
            allDay: false,
            blockAll: val.allDay ? val.allDay : false,
            type: 'confirmed',
          };
        });
      }
      const db = getDatabase();
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const batchedDatesEvents = [];
      fullBatchedAppointmentsArr.forEach((el) => {
        const rule = rrulestr(el.rrule);
        const ruleDtstart = moment(rule.origOptions.dtstart).format('YYYY-MM-DD');
        const batchedDates = rule.between(moment(start, 'YYYY-MM-DD').subtract(1, 'day').toDate(), moment(end, 'YYYY-MM-DD').add(1, 'day').endOf('day').toDate(), true);
        const duration = moment.duration(el.appointmentModel.duration).asMinutes() || 15;
        const timeStr = moment(el.appointmentModel.time, 'YYYY-MM-DD HH:mm').format('HH:mm');
        batchedDates.forEach((date) => {
          const dateStr = moment(date).utcOffset(0).format('YYYY-MM-DD');
          if (dateStr >= ruleDtstart) {
            let alreadyPersisted = false;
            if (persistedBatchedAppointments) {
              alreadyPersisted = Object.values(persistedBatchedAppointments).some((obj) => {
                const splittedTime = obj.time.split(' ')[0];
                if (splittedTime === dateStr && el.batchedId === obj.batchedId) {
                  return true;
                }
                return false;
              });
            }
            if (!alreadyPersisted) {
              const startTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').toDate();
              const endTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').add(duration, 'm').toDate();
              const pushKey = push(child(ref(db), `/requests/${uid}/${agendaId}/confirmed`)).key;
              if (el.blocked) {
                batchedDatesEvents.push({
                  ...el.appointmentModel,
                  batchedId: el.batchedId,
                  id: pushKey,
                  time: `${dateStr} ${timeStr}`,
                  start: startTime,
                  end: endTime,
                  type: 'confirmed',
                });
              } else {
                batchedDatesEvents.push({
                  ...el.appointmentModel,
                  batchedId: el.batchedId,
                  id: pushKey,
                  time: `${dateStr} ${timeStr}`,
                  start: startTime,
                  end: endTime,
                  allDay: false,
                  blockAll: false,
                  type: 'confirmed',
                });
              }
            }
          }
        });
      });
      analyticsConfirmed = [...analyticsConfirmed, ...batchedDatesEvents];
      if (canceled.val()) {
        analyticsCanceled = _.map(canceled.val(), (val, id) => {
          const { duration } = val;
          return {
            ...val,
            start: moment(val.time, 'YYYY/MM/DD HH:mm').toDate(),
            id,
            end: moment(val.time, 'YYYY/MM/DD HH:mm').add(moment.duration(duration).asMinutes(), 'm').toDate(),
            allDay: false,
            blockAll: val.allDay ? val.allDay : false,
            canceled: val.canceled ? val.canceled : true,
            type: 'canceled',
          };
        });
      }
      if (pending.val()) {
        analyticsPending = _.map(pending.val(), (val, id) => (
          {
            ...val,
            id,
            pending: true,
            type: 'pending',
          }
        ));
      }
      if (rejected.val()) {
        analyticsRejected = _.map(rejected.val(), (val, id) => (
          {
            ...val,
            id,
            pending: true,
            type: 'pending',
          }
        ));
      }
      if (accepted.val()) {
        analyticsAccepted = _.map(accepted.val(), (val, id) => (
          {
            ...val,
            id,
            pending: true,
            type: 'pending',
          }
        ));
      }
      yield put({
        type: analyticsActions.GET_RANGE_APPOINTMENTS_SUCCESS,
        payload: {
          appointments: {
            confirmed: analyticsConfirmed,
            canceled: analyticsCanceled,
            pending: analyticsPending,
            rejected: analyticsRejected,
            accepted: analyticsAccepted,
          },
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: analyticsActions.GET_RANGE_APPOINTMENTS_ERROR });
    }
  });
}

function createQueryPatientsAnalyticsStatusListener(mainUser, unified = {}, addressUid) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    let statusRef = null;
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      statusRef = ref(db, `unified/${unified.id}/queryPatientsAnalytics/status`);
    } else {
      statusRef = ref(db, `users/${uid}/queryPatientsAnalytics/status`);
    }
    const unsubscribe = onValue(statusRef, (req) => (
      emit(req)
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* checkQueryPatientsAnalyticsStatusRequest() {
  yield takeEvery(analyticsActions.VERIFY_PATIENTS_QUERY_REQUEST, function* () {
    const mainUser = yield select(getMainUserFromStore);
    const unified = yield select(getUnifiedTokenStore);
    const addressUid = yield select(getSelectedAddressFromStore);
    const statusListener = yield call(createQueryPatientsAnalyticsStatusListener, mainUser, unified, addressUid);
    yield takeEvery(statusListener, function* (status) {
      yield put({
        type: analyticsActions.SET_QUERY_PATIENTS_ANALYTICS_STATUS,
        payload: status.val(),
      });
    });
  });
}

function fetchQueryPatientsAnalyticsVersionFromDB() {
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, 'utils/queryPatientsAnalytics/version'));
}

function getQueryPatientsCountFromDB(
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryPatients',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryPatients',
    );
  }
  // const constraints = [];
  // constraints.push(where('active', '==', true));
  const q = fsQuery(
    colRef,
    // ...constraints,
  );
  return getCountFromServer(q);
}

function getQueryPatientsAnalyticsCountFromDB(
  version,
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryPatientsAnalytics',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryPatientsAnalytics',
    );
  }
  const constraints = [];
  constraints.push(where('version', '==', version));
  const q = fsQuery(
    colRef,
    ...constraints,
  );
  return getCountFromServer(q);
}

function triggerGenerateQueryPatientsAnalyticsOnDB(mainUser, unified = {}, addressUid) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`/unified/${unified.id}/queryPatientsAnalytics/status`] = 'awaiting';
  } else {
    updates[`/users/${uid}/queryPatientsAnalytics/status`] = 'awaiting';
  }
  return update(dbRef, updates);
}

export function* verifyPatientsQuery() {
  yield takeLatest(analyticsActions.VERIFY_PATIENTS_QUERY_REQUEST, function* () {
    try {
      // yield put({ type: actions.QUERYING_TAGS });
      // const { tags, mode, pagination } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const queryPatientsAnalyticsStatus = yield select(getQueryPatientsAnalyticsStatusFromStore);
      let checkedStatusOnce = yield select(getCheckedStatusOnceFromStore);
      if (!checkedStatusOnce) {
        yield take(analyticsActions.SET_QUERY_PATIENTS_ANALYTICS_STATUS);
        checkedStatusOnce = yield select(getCheckedStatusOnceFromStore);
      }
      const queryPatientsVersion = yield call(fetchQueryPatientsAnalyticsVersionFromDB);
      const [
        queryPatientsCountResponse,
        queryPatientsAnalyticsCountResponse,
      ] = yield all([
        call(getQueryPatientsCountFromDB, mainUser, unified, addressUid),
        call(getQueryPatientsAnalyticsCountFromDB, queryPatientsVersion.val(), mainUser, unified, addressUid),
      ]);
      yield all([
        put({
          type: analyticsActions.SET_QUERY_PATIENTS_VERSION,
          payload: queryPatientsVersion.val(),
        }),
        put({
          type: analyticsActions.SET_QUERY_PATIENTS_COUNT,
          payload: queryPatientsCountResponse.data().count,
        }),
        put({
          type: analyticsActions.SET_QUERY_PATIENTS_ANALYTICS_COUNT,
          payload: queryPatientsAnalyticsCountResponse.data().count,
        }),
      ]);
      // if (queryPatientsAnalyticsCountResponse.data().count < queryPatientsCountResponse.data().count) {
      if (queryPatientsAnalyticsStatus !== 'awaiting' && _.isUndefined(queryPatientsAnalyticsStatus)) {
        // Need to trigger function to generate queryPatientsAnalytics
        yield call(triggerGenerateQueryPatientsAnalyticsOnDB, mainUser, unified, addressUid);
        yield put({
          type: analyticsActions.SET_STATUS_WAS_SET_TO_AWAITING,
        });
      }
    } catch (error) {
      console.warn(error);
      // yield put({
      //   type: actions.QUERY_TAGS_ERROR,
      // });
    }
  });
}

function patientsFirestoreQueryFromDB(
  queryPatientsVersion,
  filters,
  append,
  lastDoc,
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryPatientsAnalytics',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryPatientsAnalytics',
    );
  }
  const constraints = [];
  constraints.push(where('version', '==', queryPatientsVersion));
  if (filters.plans.length > 0) {
    constraints.push(where('plans', 'array-contains-any', filters.plans));
  }
  if (filters.gender.length > 0) {
    filters.gender.forEach((el) => {
      constraints.push(where('gender', '==', el));
    });
  }
  if (filters.cities.length > 0) {
    filters.cities.forEach((el) => {
      constraints.push(where('city', '==', el));
    });
  }
  if (filters.states.length > 0) {
    filters.states.forEach((el) => {
      constraints.push(where('state', '==', el));
    });
  }
  if (filters.referral.length > 0) {
    filters.referral.forEach((el) => {
      constraints.push(where(`referralHistory.${el}`, '==', true));
    });
  }
  if (filters.referredTo.length > 0) {
    filters.referredTo.forEach((el) => {
      constraints.push(where(`referredTo.${el}`, '==', true));
    });
  }
  // if (filters.birthday && filters.birthdayFilterMode) {
  //   if (filters.birthdayFilterMode === 'exact') {
  //     const start = moment().tz('America/Sao_Paulo').subtract(filters.birthday + 1, 'year').endOf('day')
  //       .format('YYYY-MM-DD');
  //     const end = moment().tz('America/Sao_Paulo').subtract(filters.birthday, 'year').endOf('day')
  //       .format('YYYY-MM-DD');
  //     constraints.push(where('birthday', '>', start));
  //     constraints.push(where('birthday', '<=', end));
  //   }
  //   if (filters.birthdayFilterMode === 'more') {
  //     const start = moment().tz('America/Sao_Paulo').subtract(filters.birthday, 'year').startOf('day')
  //       .format('YYYY-MM-DD');
  //     constraints.push(where('birthday', '<=', start));
  //   }
  //   if (filters.birthdayFilterMode === 'less') {
  //     const start = moment().tz('America/Sao_Paulo').subtract(filters.birthday, 'year').startOf('day')
  //       .format('YYYY-MM-DD');
  //     constraints.push(where('birthday', '>=', start));
  //   }
  // }
  if (filters.privateAppointment) {
    constraints.push(where('privateAppointment', '==', true));
  }
  if (filters.tags.length > 0) {
    if (filters.filterTagMode === 'and') {
      // AND query
      filters.tags.forEach((tagId) => {
        constraints.push(where(`tagsObj.${tagId}`, '==', true));
      });
    } else {
      // OR query
      const orConstraints = filters.tags.map((tagId) => where(`tagsObj.${tagId}`, '==', true));
      constraints.push(
        or(...orConstraints),
      );
    }
  }
  const paginationConstraints = [];
  if (append && lastDoc) {
    paginationConstraints.push(startAfter(lastDoc));
  }
  const q = fsQuery(
    colRef,
    and(
      ...constraints,
    ),
    ...paginationConstraints,
    limit(10),
  );
  return getDocs(q);
}

function getQueryCountFromDB(
  queryPatientsVersion,
  filters,
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryPatientsAnalytics',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryPatientsAnalytics',
    );
  }
  const constraints = [];
  constraints.push(where('version', '==', queryPatientsVersion));
  if (filters.plans.length > 0) {
    constraints.push(where('plans', 'array-contains-any', filters.plans));
  }
  if (filters.gender.length > 0) {
    filters.gender.forEach((el) => {
      constraints.push(where('gender', '==', el));
    });
  }
  if (filters.cities.length > 0) {
    filters.cities.forEach((el) => {
      constraints.push(where('city', '==', el));
    });
  }
  if (filters.states.length > 0) {
    filters.states.forEach((el) => {
      constraints.push(where('state', '==', el));
    });
  }
  if (filters.referral.length > 0) {
    filters.referral.forEach((el) => {
      constraints.push(where(`referralHistory.${el}`, '==', true));
    });
  }
  if (filters.referredTo.length > 0) {
    filters.referredTo.forEach((el) => {
      constraints.push(where(`referredTo.${el}`, '==', true));
    });
  }
  // if (filters.birthday && filters.birthdayFilterMode) {
  //   if (filters.birthdayFilterMode === 'exact') {
  //     const start = moment().tz('America/Sao_Paulo').subtract(filters.birthday + 1, 'year').endOf('day')
  //       .format('YYYY-MM-DD');
  //     const end = moment().tz('America/Sao_Paulo').subtract(filters.birthday, 'year').endOf('day')
  //       .format('YYYY-MM-DD');
  //     constraints.push(where('birthday', '>', start));
  //     constraints.push(where('birthday', '<=', end));
  //   }
  //   if (filters.birthdayFilterMode === 'more') {
  //     const start = moment().tz('America/Sao_Paulo').subtract(filters.birthday, 'year').startOf('day')
  //       .format('YYYY-MM-DD');
  //     constraints.push(where('birthday', '<=', start));
  //   }
  //   if (filters.birthdayFilterMode === 'less') {
  //     const start = moment().tz('America/Sao_Paulo').subtract(filters.birthday, 'year').startOf('day')
  //       .format('YYYY-MM-DD');
  //     constraints.push(where('birthday', '>=', start));
  //   }
  // }
  if (filters.privateAppointment) {
    constraints.push(where('privateAppointment', '==', true));
  }
  if (filters.tags.length > 0) {
    if (filters.filterTagMode === 'and') {
      // AND query
      filters.tags.forEach((tagId) => {
        constraints.push(where(`tagsObj.${tagId}`, '==', true));
      });
    } else {
      // OR query
      const orConstraints = filters.tags.map((tagId) => where(`tagsObj.${tagId}`, '==', true));
      constraints.push(
        or(...orConstraints),
      );
    }
  }
  const q = fsQuery(
    colRef,
    and(
      ...constraints,
    ),
  );
  return getCountFromServer(q);
}

function fetchPatientsPerIdFromDB(idArray = [], addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const promises = [];
  idArray.forEach((id) => {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      promises.push(
        get(child(dbRef, `unified/${unified.id}/patients/${id}`)),
        // db.ref(`unified/${unified.id}/patients/${id}`)
        //   .once('value'),
      );
    } else {
      promises.push(
        get(child(dbRef, `users/${uid}/patients/${id}`)),
        // db.ref(`users/${uid}/patients/${id}`)
        //   .once('value'),
      );
    }
  });
  return Promise.all(promises);
}

function fetchPatientsTagsFromDB(idArray = [], mainUser, unified = {}, addressUid) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const promises = [];
  idArray.forEach((tagId) => {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      promises.push(
        get(child(dbRef, `unified/${unified.id}/tags/patients/${tagId}`)),
      );
    } else {
      promises.push(
        get(child(dbRef, `users/${uid}/tags/patients/${tagId}`)),
      );
    }
  });
  return Promise.all(promises);
}

export function* queryPatientsRequest() {
  yield takeLatest(analyticsActions.QUERY_PATIENTS_REQUEST, function* (action) {
    try {
      yield put({ type: analyticsActions.QUERYING_PATIENTS });
      const { filters, pagination } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      let queryPatientsVersion = yield select(getQueryPatientsAnalyticsVersionFromStore);
      if (!queryPatientsVersion) {
        yield take(analyticsActions.SET_QUERY_PATIENTS_VERSION);
        queryPatientsVersion = yield select(getQueryPatientsAnalyticsVersionFromStore);
      }
      const patientsListFromStore = yield select(getPatientsListFromStoreFromStore);
      const lastVisibleDocFromStore = yield select(getLastVisibleDocFromStore);
      const queryCountFromStore = yield select(getQueryCountFromStore);
      const queryIndexFromStore = yield select(getQueryIndexFromStore);
      let queryIndex = 1;
      if (pagination > queryIndexFromStore) {
        queryIndex = pagination;
      }
      // const lastDoc = yield select(getLastDocFromStore);
      const patientsFirestoreArr = [];
      const querySnapshot = yield call(
        patientsFirestoreQueryFromDB,
        queryPatientsVersion,
        filters,
        pagination > queryIndexFromStore, // append
        pagination > queryIndexFromStore ? lastVisibleDocFromStore : null, // lastDoc
        mainUser,
        unified,
        addressUid,
      );
      let queryCount = null;
      if (!queryCountFromStore) {
        const queryCountResponse = yield call(
          getQueryCountFromDB,
          queryPatientsVersion,
          filters,
          mainUser,
          unified,
          addressUid,
        );
        queryCount = queryCountResponse.data().count;
      } else {
        queryCount = queryCountFromStore;
      }
      let noNextPage = false;
      const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      querySnapshot.forEach((document) => {
        patientsFirestoreArr.push(document.id);
      });
      const patientsArr = [];
      if (querySnapshot.empty) {
        noNextPage = true;
      } else {
        const promisesResponse = yield call(
          fetchPatientsPerIdFromDB,
          patientsFirestoreArr,
          addressUid,
          unified,
          mainUser,
        );
        promisesResponse.forEach((r, index) => {
          if (r.val()) {
            patientsArr.push({
              ...r.val(),
              id: r.key,
            });
          } else {
            console.warn(`Patient id (${patientsFirestoreArr[index]}) could not be downloaded`);
          }
        });
        const tagsPromisesResponse = yield call(
          fetchPatientsTagsFromDB,
          patientsFirestoreArr,
          mainUser,
          unified,
          addressUid,
        );
        tagsPromisesResponse.forEach((r, index) => {
          if (r.val()) {
            patientsArr[index].tagsObj = { ...r.val() };
          }
        });
      }
      yield put({
        type: analyticsActions.QUERY_PATIENTS_SUCCESS,
        payload: {
          patientsList: patientsListFromStore ? [...patientsListFromStore, ...patientsArr] : patientsArr,
          noNextPage,
          lastVisibleDoc,
          lastFiltersSearched: filters,
          queryCount,
          queryIndex,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: analyticsActions.QUERY_PATIENTS_ERROR,
      });
    }
  });
}

function tagsParamFirestoreQueryFromDB(
  queryPatientsVersion,
  tag,
  queryItems,
  append,
  lastDoc,
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryTagsPerValue',
      tag,
      'patients',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryTagsPerValue',
      tag,
      'patients',
    );
  }
  const constraints = [];
  // constraints.push(where('version', '==', queryPatientsVersion));
  queryItems.forEach((el) => {
    if (el.filterModeParam && el.inputParam) {
      constraints.push(where('value', el.filterModeParam, el.inputParam));
    }
  });
  // constraints.push(where('value', mode, value));
  if (append && lastDoc) {
    constraints.push(startAfter(lastDoc));
  }
  const q = fsQuery(
    colRef,
    ...constraints,
    limit(10),
  );
  return getDocs(q);
}

function getQueryCountParamFromDB(
  queryPatientsVersion,
  tag,
  queryItems,
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryTagsPerValue',
      tag,
      'patients',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryTagsPerValue',
      tag,
      'patients',
    );
  }
  const constraints = [];
  // constraints.push(where('version', '==', queryPatientsVersion));
  queryItems.forEach((el) => {
    if (el.filterModeParam && el.inputParam) {
      constraints.push(where('value', el.filterModeParam, el.inputParam));
    }
  });
  // constraints.push(where('value', mode, value));
  const q = fsQuery(
    colRef,
    ...constraints,
  );
  return getCountFromServer(q);
  // return fs.collection('professionals').doc(uid).collection('queryPatients')
  //   .where('keywords', 'array-contains', startToUse)
  //   .where('active', '==', active)
  //   .orderBy('keywords')
  //   .startAfter(paginateAt)
  //   .limit(20)
  //   .get();
}

export function* queryParamTagRequest() {
  yield takeLatest(analyticsActions.QUERY_PARAM_TAG_REQUEST, function* (action) {
    try {
      yield put({ type: analyticsActions.QUERYING_TAGS });
      const {
        tag,
        queryItems,
        pagination,
      } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      let queryPatientsVersion = yield select(getQueryPatientsAnalyticsVersionFromStore);
      if (!queryPatientsVersion) {
        yield take(analyticsActions.SET_QUERY_PATIENTS_VERSION);
        queryPatientsVersion = yield select(getQueryPatientsAnalyticsVersionFromStore);
      }
      const patientsListFromStore = yield select(getPatientsListParamFromStore);
      const lastVisibleDocFromStore = yield select(getLastVisibleDocParamFromStore);
      const queryCountFromStore = yield select(getQueryCountParamFromStore);
      const queryIndexFromStore = yield select(getQueryIndexParamFromStore);
      let queryIndex = 1;
      if (pagination > queryIndexFromStore) {
        queryIndex = pagination;
      }
      const patientsFirestoreArr = [];
      const querySnapshot = yield call(
        tagsParamFirestoreQueryFromDB,
        queryPatientsVersion,
        tag,
        queryItems,
        pagination > queryIndexFromStore, // append
        pagination > queryIndexFromStore ? lastVisibleDocFromStore : null, // lastDoc
        mainUser,
        unified,
        addressUid,
      );
      let queryCount = null;
      if (!queryCountFromStore) {
        const queryCountResponse = yield call(
          getQueryCountParamFromDB,
          queryPatientsVersion,
          tag,
          queryItems,
          mainUser,
          unified,
          addressUid,
        );
        queryCount = queryCountResponse.data().count;
      } else {
        queryCount = queryCountFromStore;
      }
      let noNextPage = false;
      const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      querySnapshot.forEach((document) => {
        patientsFirestoreArr.push(document.id);
      });
      const patientsArr = [];
      if (querySnapshot.empty) {
        noNextPage = true;
      } else {
        if (querySnapshot.size < 10) {
          noNextPage = true;
        }
        const promisesResponse = yield call(
          fetchPatientsPerIdFromDB,
          patientsFirestoreArr,
          addressUid,
          unified,
          mainUser,
        );
        promisesResponse.forEach((r, index) => {
          if (r.val()) {
            patientsArr.push({
              ...r.val(),
              id: r.key,
            });
          } else {
            console.warn(`Patient id (${patientsFirestoreArr[index]}) could not be downloaded`);
          }
        });
        const tagsPromisesResponse = yield call(
          fetchPatientsTagsFromDB,
          patientsFirestoreArr,
          mainUser,
          unified,
          addressUid,
        );
        tagsPromisesResponse.forEach((r, index) => {
          if (r.val()) {
            patientsArr[index].tagsObj = { ...r.val() };
          }
        });
      }
      yield put({
        type: analyticsActions.QUERY_PARAM_TAG_SUCCESS,
        payload: {
          patientsList: patientsListFromStore ? [...patientsListFromStore, ...patientsArr] : patientsArr,
          noNextPage,
          lastVisibleDoc,
          lastTagsSearched: tag,
          lastQueryItemSearched: queryItems,
          // lastModeSearched: mode,
          // lastValueSearched: value,
          queryCount,
          queryIndex,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: analyticsActions.QUERY_PARAM_TAG_ERROR,
      });
    }
  });
}

function getQueryPatientsAnalyticsInfoOnDB(mainUser, unified = {}, addressUid) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `unified/${unified.id}/queryPatientsAnalyticsInfo`));
  }
  return get(child(dbRef, `users/${uid}/queryPatientsAnalyticsInfo`));
}

export function* getQueryPatientsAnalyticsInfo() {
  yield takeLatest(analyticsActions.SET_QUERY_PATIENTS_ANALYTICS_STATUS, function* () {
    try {
      const queryPatientsAnalyticsStatus = yield select(getQueryPatientsAnalyticsStatusFromStore);
      const checkedStatusOnce = yield select(getCheckedStatusOnceFromStore);
      if (_.isNull(queryPatientsAnalyticsStatus) && checkedStatusOnce) {
        const mainUser = yield select(getMainUserFromStore);
        const unified = yield select(getUnifiedTokenStore);
        const addressUid = yield select(getSelectedAddressFromStore);
        const infos = yield call(getQueryPatientsAnalyticsInfoOnDB, mainUser, unified, addressUid);
        if (infos.val()) {
          const { cities, states } = infos.val();
          if (cities) {
            const citiesOptions = Object.keys(cities).map((el) => ({ name: el }));
            yield put({
              type: analyticsActions.SET_CITIES_OPTIONS,
              payload: citiesOptions,
            });
          }
          if (states) {
            const statesOptions = Object.keys(states).map((el) => ({ name: el }));
            yield put({
              type: analyticsActions.SET_STATES_OPTIONS,
              payload: statesOptions,
            });
          }
        }
      }
    } catch (error) {
      console.warn(error);
      // yield put({
      //   type: actions.QUERY_TAGS_ERROR,
      // });
    }
  });
}

function generateAnalyticsCsv(
  mode,
  args,
  selectedId,
  idToken,
  mainUser,
  addressUid,
) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    mode,
    args,
    currentUser: currentUser.uid,
    selectedId,
    addressUid,
  };
  // return { status: 201 };
  return axios.post(
    `${ROOT_URL}/generateAnalyticsCsv`,
    bodyParameters,
    config,
  );
}

export function* generateCsv() {
  yield takeEvery(analyticsActions.GENERATE_CSV_REQUEST, function* (action) {
    try {
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const { mode, args, selectedId } = action.payload;
      yield call(generateAnalyticsCsv, mode, args, selectedId, idToken, mainUser, addressUid);
      // yield put({
      //   type: analyticsActions.GOOGLE_ANALYTICS_SUCCESS,
      //   payload: data.data,
      // });
    } catch (error) {
      console.warn(error);
    }
  });
}

function createAnalyticsCsvListener(mode, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    // let infoRef = null;
    // if (unified.id && unified.address.some((a) => a === addressUid)) {
    //   infoRef = ref(db, `unified/${unified.id}/queryPatientsAnalyticsInfo/csv/${mode}`);
    // } else {
    //   infoRef = ref(db, `users/${uid}/queryPatientsAnalyticsInfo/csv/${mode}`);
    // }
    const csvRef = ref(db, `users/${uid}/queryPatientsAnalyticsInfo/csv/${mode}`);
    const queryRef = dbQuery(
      csvRef,
      orderByKey(),
      limitToLast(10),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req)
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getPatientsCsvListRequest() {
  yield takeEvery(analyticsActions.GET_PATIENTS_CSV_LIST_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const csvListener = yield call(createAnalyticsCsvListener, 'patients', mainUser);
      yield takeEvery(csvListener, function* (data) {
        let arr = [];
        if (data.val()) {
          arr = _.map(data.val(), (val, id) => (
            { ...val, id }
          ));
        }
        const orderedArr = _.orderBy(arr, ['timestamp'], ['desc']);
        const first10 = orderedArr.slice(0, 10);
        // const excludeItems = orderedArr.slice(11);
        yield put({
          type: analyticsActions.GET_PATIENTS_CSV_LIST_SUCCESS,
          payload: first10,
        });
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* getTagsParamCsvListRequest() {
  yield takeLatest(analyticsActions.GET_TAGS_PARAM_CSV_LIST_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const csvListener = yield call(createAnalyticsCsvListener, 'tagsParam', mainUser);
      yield takeEvery(csvListener, function* (data) {
        let arr = [];
        if (data.val()) {
          arr = _.map(data.val(), (val, id) => (
            { ...val, id }
          ));
        }
        const orderedArr = _.orderBy(arr, ['timestamp'], ['desc']);
        const first10 = orderedArr.slice(0, 10);
        // const excludeItems = orderedArr.slice(11);
        yield put({
          type: analyticsActions.GET_TAGS_PARAM_CSV_LIST_SUCCESS,
          payload: first10,
        });
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function downloadCsv(id, mode, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const storage = getStorage();
  // if (unified.id && unified.address.some((a) => a === addressUid)) {
  //   return getDownloadURL(storageRef(storage, `unified/${unified.id}/queryPatientsAnalyticsInfo/csv/${mode}/${id}/pacientes.csv`));
  // }
  return getDownloadURL(storageRef(storage, `users/${uid}/queryPatientsAnalyticsInfo/csv/${mode}/${id}/pacientes.csv`));
}

export function* downloadCsvRequest() {
  yield takeLatest(analyticsActions.DOWNLOAD_CSV_REQUEST, function* (action) {
    try {
      const { id, mode } = action.payload;
      const mainUser = yield select(getMainUserFromStore);
      const url = yield call(downloadCsv, id, mode, mainUser);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `pacientes-${new Date().getTime().toString()}.csv`);
      link.target = '_blank';
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.warn(error);
    }
  });
}

function getTotalPatientsCountFromDB(
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryPatients',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryPatients',
    );
  }
  const constraints = [];
  constraints.push(where('active', '==', true));
  const q = fsQuery(
    colRef,
    ...constraints,
  );
  return getCountFromServer(q);
}

export function* getTotalPatientsRequest() {
  yield takeEvery(analyticsActions.GET_TOTAL_PATIENTS_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const queryCountResponse = yield call(
        getTotalPatientsCountFromDB,
        mainUser,
        unified,
        addressUid,
      );
      const queryCount = queryCountResponse.data().count;
      yield put({
        type: analyticsActions.GET_TOTAL_PATIENTS_SUCCESS,
        payload: queryCount,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function getGenderCountFromDB(
  queryPatientsVersion,
  gender,
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryPatientsAnalytics',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryPatientsAnalytics',
    );
  }
  const constraints = [];
  constraints.push(where('version', '==', queryPatientsVersion));
  constraints.push(where('gender', '==', gender));
  const q = fsQuery(
    colRef,
    ...constraints,
  );
  return getCountFromServer(q);
}

export function* getGenderCountRequest() {
  yield takeEvery(analyticsActions.GET_GENDER_COUNT_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      let queryPatientsVersion = yield select(getQueryPatientsAnalyticsVersionFromStore);
      if (!queryPatientsVersion) {
        yield take(analyticsActions.SET_QUERY_PATIENTS_VERSION);
        queryPatientsVersion = yield select(getQueryPatientsAnalyticsVersionFromStore);
      }
      const [
        maleCountResponse,
        femaleCountResponse,
        othersCountResponse,
      ] = yield all([
        call(getGenderCountFromDB, queryPatientsVersion, 'male', mainUser, unified, addressUid),
        call(getGenderCountFromDB, queryPatientsVersion, 'female', mainUser, unified, addressUid),
        call(getGenderCountFromDB, queryPatientsVersion, 'others', mainUser, unified, addressUid),
      ]);
      const maleCount = maleCountResponse.data().count;
      const femaleCount = femaleCountResponse.data().count;
      const othersCount = othersCountResponse.data().count;
      yield put({
        type: analyticsActions.GET_GENDER_COUNT_SUCCESS,
        payload: {
          male: maleCount,
          female: femaleCount,
          others: othersCount,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function getAgeCountFromDB(
  queryPatientsVersion,
  constraints,
  mainUser,
  unified = {},
  addressUid,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let colRef = null;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryPatientsAnalytics',
    );
  } else {
    colRef = collection(
      fs,
      'professionals',
      uid,
      'queryPatientsAnalytics',
    );
  }
  const finalConstraints = [];
  finalConstraints.push(where('version', '==', queryPatientsVersion));
  const q = fsQuery(
    colRef,
    ...finalConstraints,
    ...constraints,
  );
  return getCountFromServer(q);
}

export function* getAgeCountRequest() {
  yield takeEvery(analyticsActions.GET_AGE_COUNT_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      let queryPatientsVersion = yield select(getQueryPatientsAnalyticsVersionFromStore);
      if (!queryPatientsVersion) {
        yield take(analyticsActions.SET_QUERY_PATIENTS_VERSION);
        queryPatientsVersion = yield select(getQueryPatientsAnalyticsVersionFromStore);
      }
      // 0-9 years
      const constraints0To9 = [];
      const start0To9 = moment().tz('America/Sao_Paulo').subtract(9, 'year').startOf('day')
        .format('YYYY-MM-DD');
      constraints0To9.push(where('birthday', '>=', start0To9));
      // 10-19 years
      const constraints10To19 = [];
      const start10To19 = moment().tz('America/Sao_Paulo').subtract(19 + 1, 'year').endOf('day')
        .format('YYYY-MM-DD');
      const end10To19 = moment().tz('America/Sao_Paulo').subtract(10, 'year').endOf('day')
        .format('YYYY-MM-DD');
      constraints10To19.push(where('birthday', '>', start10To19));
      constraints10To19.push(where('birthday', '<=', end10To19));
      // 20-29 years
      const constraints20To29 = [];
      const start20To29 = moment().tz('America/Sao_Paulo').subtract(29 + 1, 'year').endOf('day')
        .format('YYYY-MM-DD');
      const end20To29 = moment().tz('America/Sao_Paulo').subtract(20, 'year').endOf('day')
        .format('YYYY-MM-DD');
      constraints20To29.push(where('birthday', '>', start20To29));
      constraints20To29.push(where('birthday', '<=', end20To29));
      // 30-39 years
      const constraints30To39 = [];
      const start30To39 = moment().tz('America/Sao_Paulo').subtract(39 + 1, 'year').endOf('day')
        .format('YYYY-MM-DD');
      const end30To39 = moment().tz('America/Sao_Paulo').subtract(30, 'year').endOf('day')
        .format('YYYY-MM-DD');
      constraints30To39.push(where('birthday', '>', start30To39));
      constraints30To39.push(where('birthday', '<=', end30To39));
      // 40-49 years
      const constraints40To49 = [];
      const start40To49 = moment().tz('America/Sao_Paulo').subtract(49 + 1, 'year').endOf('day')
        .format('YYYY-MM-DD');
      const end40To49 = moment().tz('America/Sao_Paulo').subtract(40, 'year').endOf('day')
        .format('YYYY-MM-DD');
      constraints40To49.push(where('birthday', '>', start40To49));
      constraints40To49.push(where('birthday', '<=', end40To49));
      // 50-59 years
      const constraints50To59 = [];
      const start50To59 = moment().tz('America/Sao_Paulo').subtract(59 + 1, 'year').endOf('day')
        .format('YYYY-MM-DD');
      const end50To59 = moment().tz('America/Sao_Paulo').subtract(50, 'year').endOf('day')
        .format('YYYY-MM-DD');
      constraints50To59.push(where('birthday', '>', start50To59));
      constraints50To59.push(where('birthday', '<=', end50To59));
      // 60-69 years
      const constraints60To69 = [];
      const start60To69 = moment().tz('America/Sao_Paulo').subtract(69 + 1, 'year').endOf('day')
        .format('YYYY-MM-DD');
      const end60To69 = moment().tz('America/Sao_Paulo').subtract(60, 'year').endOf('day')
        .format('YYYY-MM-DD');
      constraints60To69.push(where('birthday', '>', start60To69));
      constraints60To69.push(where('birthday', '<=', end60To69));
      // 70-79 years
      const constraints70To79 = [];
      const start70To79 = moment().tz('America/Sao_Paulo').subtract(79 + 1, 'year').endOf('day')
        .format('YYYY-MM-DD');
      const end70To79 = moment().tz('America/Sao_Paulo').subtract(70, 'year').endOf('day')
        .format('YYYY-MM-DD');
      constraints70To79.push(where('birthday', '>', start70To79));
      constraints70To79.push(where('birthday', '<=', end70To79));
      // 80 years
      const constraints80Plus = [];
      const start80Plus = moment().tz('America/Sao_Paulo').subtract(80, 'year').startOf('day')
        .format('YYYY-MM-DD');
      constraints80Plus.push(where('birthday', '<=', start80Plus));
      const [
        response0To9,
        response10To19,
        response20To29,
        response30To39,
        response40To49,
        response50To59,
        response60To69,
        response70To79,
        response80Plus,
      ] = yield all([
        call(getAgeCountFromDB, queryPatientsVersion, constraints0To9, mainUser, unified, addressUid),
        call(getAgeCountFromDB, queryPatientsVersion, constraints10To19, mainUser, unified, addressUid),
        call(getAgeCountFromDB, queryPatientsVersion, constraints20To29, mainUser, unified, addressUid),
        call(getAgeCountFromDB, queryPatientsVersion, constraints30To39, mainUser, unified, addressUid),
        call(getAgeCountFromDB, queryPatientsVersion, constraints40To49, mainUser, unified, addressUid),
        call(getAgeCountFromDB, queryPatientsVersion, constraints50To59, mainUser, unified, addressUid),
        call(getAgeCountFromDB, queryPatientsVersion, constraints60To69, mainUser, unified, addressUid),
        call(getAgeCountFromDB, queryPatientsVersion, constraints70To79, mainUser, unified, addressUid),
        call(getAgeCountFromDB, queryPatientsVersion, constraints80Plus, mainUser, unified, addressUid),
      ]);
      const array = new Array(9).fill(0);
      array[0] = response0To9.data().count;
      array[1] = response10To19.data().count;
      array[2] = response20To29.data().count;
      array[3] = response30To39.data().count;
      array[4] = response40To49.data().count;
      array[5] = response50To59.data().count;
      array[6] = response60To69.data().count;
      array[7] = response70To79.data().count;
      array[8] = response80Plus.data().count;
      yield put({
        type: analyticsActions.GET_AGE_COUNT_SUCCESS,
        payload: {
          array,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  // yield all([fork(requestsFetch)]);
  yield all([
    // fork(requestsFetch),
    fork(fetchReferalList),
    fork(fetchRequestingDoctorList),
    fork(getRangeAppointments),
    fork(checkQueryPatientsAnalyticsStatusRequest),
    fork(verifyPatientsQuery),
    fork(queryPatientsRequest),
    fork(queryParamTagRequest),
    fork(getQueryPatientsAnalyticsInfo),
    fork(generateCsv),
    fork(getPatientsCsvListRequest),
    fork(getTagsParamCsvListRequest),
    fork(downloadCsvRequest),
    fork(getTotalPatientsRequest),
    fork(getGenderCountRequest),
    fork(getAgeCountRequest),
  ]);
}
