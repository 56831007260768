import { all } from 'redux-saga/effects';
import appSagas from './app/saga';
import authSagas from './auth/saga';
import profile from './profile/saga';
import requestsSaga from './agenda/saga';
import contactsSaga from './contacts/saga';
import searchesSaga from './searches/saga';
import scheduleSaga from './schedule/saga';
import patientAppointments from './patientAppointments/saga';
import reportSaga from './report/saga';
import calendarSaga from './calendar/saga';
import proceduresSaga from './procedures/saga';
import examsSaga from './exams/saga';
import PdfSaga from './pdf/saga';
import recordsSaga from './records/saga';
import prescriptionsSaga from './prescriptions/saga';
import pinSaga from './pin/saga';
import medicinesSaga from './medicines/saga';
import cidSaga from './cid/saga';
import certificatesSaga from './certificate/saga';
import customUsersSaga from './customUsers/saga';
import chatSaga from './chat/saga';
import inventorySaga from './inventory/saga';
import financialSaga from './financial/saga';
import gapiSaga from './gapi/saga';
import messagesSaga from './messages/saga';
import analyticsSaga from './analytics/saga';
import memedSaga from './memed/saga';
import waitList from './waitList/saga';
import entrancePersistSaga from './entrancePersist/saga';
import telemedSaga from './telemed/saga';
import patientAlerts from './patientAlert/saga';
import tagsSaga from './tags/saga';

export default function* rootSaga() {
  yield all([
    analyticsSaga(),
    authSagas(),
    appSagas(),
    profile(),
    requestsSaga(),
    contactsSaga(),
    searchesSaga(),
    scheduleSaga(),
    patientAppointments(),
    reportSaga(),
    calendarSaga(),
    proceduresSaga(),
    examsSaga(),
    PdfSaga(),
    recordsSaga(),
    prescriptionsSaga(),
    pinSaga(),
    medicinesSaga(),
    cidSaga(),
    certificatesSaga(),
    customUsersSaga(),
    chatSaga(),
    inventorySaga(),
    financialSaga(),
    gapiSaga(),
    messagesSaga(),
    memedSaga(),
    waitList(),
    entrancePersistSaga(),
    telemedSaga(),
    patientAlerts(),
    tagsSaga(),
  ]);
}
