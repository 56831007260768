import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  query,
  orderByKey,
  limitToLast,
  get,
  child,
  update,
} from 'firebase/database';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  put,
  fork,
  takeEvery,
  take,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import authActions from '../auth/actions';
import profileActions from '../profile/actions';
import memedActions from '../memed/actions';
import { siteConfig } from '../../settings';
import { notification } from '../../components';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getIsProfessionalFromStore = (state) => state.Auth.professional;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getProfessionalProfileFromStore = (state) => state.Auth.professional;

const getSavedAllChangesPropFromStore = (state) => state.App.savedAllChangesProp;

const getOPenMemedFromStore = (state) => state.Memed.openMemed;

const getMaintenanceFromStore = (state) => state.App.maintenance;

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function createVersionListener() {
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    const versionRef = ref(db, 'version');
    const unsubscribe = onValue(versionRef, (req) => (
      emit(req.val() || null)
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* checkAppVersionRequest() {
  yield takeEvery(actions.CHECK_APP_VERSION, function* () {
    const versionListener = yield call(createVersionListener);
    while (true) {
      const version = yield take(versionListener);
      if (version !== siteConfig.appVersion && version !== 'testing') {
        // let hasUnsavedChanges = false;
        // do {
        //   const savedAllChangesProp = yield select(getSavedAllChangesPropFromStore);
        //   const openMemed = yield select(getOPenMemedFromStore);
        //   let hasUnsavedChangesInsideLoop = false;
        //   Object.keys(savedAllChangesProp).forEach((patientId) => {
        //     Object.keys(savedAllChangesProp[patientId]).forEach((key) => {
        //       if (!savedAllChangesProp[patientId][key]) {
        //         hasUnsavedChangesInsideLoop = true;
        //       }
        //     });
        //   });
        //   if (hasUnsavedChangesInsideLoop || openMemed) {
        //     yield take([
        //       actions.CHANGE_SAVED_ALL_CHANGES,
        //       actions.CHANGE_SPECIFIC_SAVED_ALL_CHANGES_FIELD,
        //       actions.DISCARD_SAVED_ALL_CHANGES,
        //       memedActions.SET_OPEN_MEMED,
        //     ]);
        //   }
        //   hasUnsavedChanges = hasUnsavedChangesInsideLoop || openMemed;
        // } while (hasUnsavedChanges);
        // if (!hasUnsavedChanges) {
        //   console.info('Refreshing page!');
        //   yield put({
        //     type: actions.NEED_APP_RELOAD,
        //   });
        // }
        console.info('Refreshing page!');
        yield put({
          type: actions.NEED_APP_RELOAD,
        });
      }
    }
  });
}

export function* reloadApp() {
  yield takeEvery(actions.NEED_APP_RELOAD, function* () {
    yield call(sleep, 4000);
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
      window.location.reload();
    });
  });
}

function createServiceContractListener() {
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    const serviceContractRef = ref(db, 'utils/serviceContract');
    const unsubscribe = onValue(serviceContractRef, (req) => (
      emit(req.val() || null)
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* checkServiceContract() {
  yield takeEvery(authActions.LOGIN_SUCCESS, function* () {
    const professional = yield select(getIsProfessionalFromStore);
    if (professional) {
      const serviceContractListener = yield call(createServiceContractListener);
      yield takeEvery(serviceContractListener, function* (serviceContract) {
        if (serviceContract) {
          const { version, document } = serviceContract;
          yield put({
            type: actions.FETCH_SERVICE_CONTRACT_SUCCESS,
            payload: {
              version,
              document,
            },
          });
        }
      });
      yield take([
        actions.CANCEL_LISTENERS,
      ]);
      serviceContractListener.close();
    }
  });
}

function acceptServiceContactOnDB(idToken, mainUser) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    currentUser: currentUser.uid,
  };
  return axios.post(
    `${ROOT_URL}/acceptServiceContract`,
    bodyParameters,
    config,
  );
}

export function* acceptServiceContractRequest() {
  yield takeEvery(actions.ACCEPT_SERVICE_CONTRACT_REQUEST, function* () {
    try {
      yield put({ type: actions.SAVING_SERVICE_CONTRACT });
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const { status } = yield call(
        acceptServiceContactOnDB,
        idToken,
        mainUser,
      );
      if (status === 201) {
        yield all([
          put({ type: actions.ACCEPT_SERVICE_CONTRACT_SUCCESS }),
          put({ type: profileActions.PROFILE_INFO_REQUEST }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.ACCEPT_SERVICE_CONTRACT_ERROR });
      notification(
        'error',
        'Algo deu errado para salvar o contrato. Tente novamente mais tarde.',
      );
    }
  });
}

function requestSystemUpdatesChangelogOnDB(completeChangelog) {
  const db = getDatabase();
  const dbRef = ref(db);
  if (completeChangelog) {
    return get(child(dbRef, 'changelog'));
  }
  // return new Promise((resolve) => {
  //   onValue(query(ref(db, 'changelog'), orderByKey(), limitToLast(1)), resolve, { onlyOnce: true });
  // });
  return get(query(ref(db, 'changelog'), orderByKey(), limitToLast(1)));
}

function checkCurrentChangelog() {
  return JSON.parse(localStorage.getItem('changelog'));
}

function changeCurrentChangelog(changelogFromDB) {
  return localStorage.setItem('changelog', JSON.stringify(changelogFromDB));
}

// function removeCurrentChangelog() {
//   return localStorage.removeItem('changelog');
// }

export function* requestSystemUpdatesChangelog() {
  yield takeEvery([actions.FETCH_SYSTEM_UPDATES_CHANGELOG, authActions.LOGIN_SUCCESS], function* (action) {
    try {
      let professional = yield select(getProfessionalProfileFromStore);
      if (!professional) {
        yield take(authActions.LOGIN_SUCCESS);
        professional = yield select(getProfessionalProfileFromStore);
      }
      if (professional) {
        // yield call(removeCurrentChangelog);
        const completeChangelog = action?.payload?.completeChangelog || false;
        const request = yield call(requestSystemUpdatesChangelogOnDB, completeChangelog);
        const changelogFromDB = request?.val();
        const currentChangelog = yield call(checkCurrentChangelog);
        const currentChangelogInformation = currentChangelog ? currentChangelog[Object.keys(currentChangelog)[0]] : {};
        const changelogFromDBInformation = changelogFromDB ? changelogFromDB[Object.keys(changelogFromDB)[0]] : {};

        if (completeChangelog) {
          yield put({
            type: actions.FETCH_COMPLETE_SYSTEM_UPDATES_CHANGELOG_SUCCESS,
            payload: changelogFromDB,
          });
        } else if (
          changelogFromDBInformation
          && changelogFromDBInformation?.version !== currentChangelogInformation?.version
          && changelogFromDBInformation?.visible
          && !completeChangelog) {
          yield call(changeCurrentChangelog, changelogFromDB);
          yield put({
            type: actions.FETCH_SYSTEM_UPDATES_CHANGELOG_SUCCESS,
            payload: changelogFromDB,
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function createMaintenanceListener() {
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    const maintenanceRef = ref(db, 'maintenance');
    const unsubscribe = onValue(maintenanceRef, (req) => (
      emit(req.val() || null)
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* checkMaintenanceRequest() {
  yield takeEvery(actions.CHECK_APP_VERSION, function* () {
    const maintenanceListener = yield call(createMaintenanceListener);
    while (true) {
      const maintenance = yield take(maintenanceListener);
      const maintenanceFromStore = yield select(getMaintenanceFromStore);
      if (maintenance?.active) {
        let hasUnsavedChanges = false;
        const savedAllChangesProp = yield select(getSavedAllChangesPropFromStore);
        const openMemed = yield select(getOPenMemedFromStore);
        Object.keys(savedAllChangesProp).forEach((patientId) => {
          Object.keys(savedAllChangesProp[patientId]).forEach((key) => {
            if (!savedAllChangesProp[patientId][key]) {
              hasUnsavedChanges = true;
            }
          });
        });
        if ((hasUnsavedChanges || openMemed) && !_.isUndefined(maintenanceFromStore)) {
          notification(
            'warning',
            'O Meagenda vai entrar em manutenção',
            'Em 3 minutos o sistema ficará fora do ar. Salve suas alterações antes disso.',
            180,
          );
          yield call(sleep, 180000);
        }
        yield all([
          // put({
          //   type: actions.DISCARD_SAVED_ALL_CHANGES,
          // }),
          put({
            type: memedActions.SET_OPEN_MEMED,
            payload: false,
          }),
        ]);
      }
      yield put({
        type: actions.SET_MAINTENANCE,
        payload: maintenance,
      });
    }
  });
}

function checkShowPopoverComponentToPrint() {
  return JSON.parse(localStorage.getItem('already_shown_popover_component_to_print'));
}

export function* checkShowPopoverComponentToPrintRequest() {
  yield takeEvery(authActions.LOGIN_SUCCESS, function* () {
    try {
      let professional = yield select(getProfessionalProfileFromStore);
      if (!professional) {
        yield take(authActions.LOGIN_SUCCESS);
        professional = yield select(getProfessionalProfileFromStore);
      }
      if (professional) {
        const alreadyShownPopoverComponentToPrint = yield call(checkShowPopoverComponentToPrint);
        yield put({
          type: actions.SET_SHOW_POPOVER_COMPONENT_TO_PRINT,
          payload: {
            alreadyShownPopoverComponentToPrint,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function setCheckShowPopoverComponentToPrint() {
  return localStorage.setItem('already_shown_popover_component_to_print', JSON.stringify(true));
}

export function* setShowPopoverComponentToPrintRequest() {
  yield takeEvery(actions.SAVE_ON_CACHE_SHOW_POPOVER_COMPONENT_TO_PRINT, function* () {
    try {
      yield call(setCheckShowPopoverComponentToPrint);
      yield put({
        type: actions.SET_SHOW_POPOVER_COMPONENT_TO_PRINT,
        payload: {
          alreadyShownPopoverComponentToPrint: true,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function updateSystemAlertOnDB(alert) {
  const db = getDatabase();
  const dbRef = ref(db);
  const updates = {};
  updates['/alert'] = alert;
  return update(dbRef, updates);
}

export function* updateSystemAlert() {
  yield takeEvery(actions.UPDATE_SYSTEM_ALERT, function* (action) {
    try {
      yield call(
        updateSystemAlertOnDB,
        action.payload,
      );
      yield put({
        type: actions.UPDATE_SYSTEM_ALERT_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.UPDATE_SYSTEM_ALERT_ERROR });
    }
  });
}

function createSystemAlertListener() {
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    const alertRef = ref(db, 'alert');
    const unsubscribe = onValue(alertRef, (req) => (
      emit(req.val() || null)
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* checkSystemAlert() {
  yield takeEvery(authActions.LOGIN_SUCCESS, function* () {
    const professional = yield select(getIsProfessionalFromStore);
    if (professional) {
      const systemAlertListener = yield call(createSystemAlertListener);
      yield takeEvery(systemAlertListener, function* (alert) {
        if (alert) {
          yield put({
            type: actions.FETCH_SYSTEM_ALERT_SUCCESS,
            payload: alert,
          });
        }
      });
      yield take([
        actions.CANCEL_LISTENERS,
      ]);
      systemAlertListener.close();
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAppVersionRequest),
    fork(reloadApp),
    fork(checkServiceContract),
    fork(acceptServiceContractRequest),
    fork(requestSystemUpdatesChangelog),
    fork(checkMaintenanceRequest),
    fork(checkShowPopoverComponentToPrintRequest),
    fork(setShowPopoverComponentToPrintRequest),
    fork(updateSystemAlert),
    fork(checkSystemAlert),
  ]);
}
