import actions from './actions';
import authActions from '../auth/actions';

const initState = {};

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload.tags,
        loadingTags: false,
      };
    case actions.FETCHING_TAGS:
      return {
        ...state,
        loadingTags: true,
      };
    case actions.GET_TAGS_ERROR:
      return {
        ...state,
        loadingTags: null,
      };
    case actions.GET_PATIENT_TAGS_SUCCESS:
      return {
        ...state,
        patientTags: {
          [action.payload.seectedId]: action.payload.tags,
        },
        loadingPatientTags: false,
      };
    case actions.FETCHING_PATIENT_TAGS:
      return {
        ...state,
        loadingPatientTags: true,
      };
    case actions.GET_PATIENT_TAGS_ERROR:
      return {
        ...state,
        loadingPatientTags: null,
      };
    case actions.CREATING_TAG:
      return {
        ...state,
        creatingTag: true,
      };
    case actions.CREATE_TAG_SUCCESS:
      return {
        ...state,
        creatingTag: false,
      };
    case actions.CREATE_TAG_ERROR:
      return {
        ...state,
        creatingTag: null,
      };
    case actions.UPDATE_TAG_SUCCESS:
      return {
        ...state,
        creatingTag: false,
      };
    case actions.UPDATE_TAG_ERROR:
      return {
        ...state,
        creatingTag: null,
      };
    default:
      return state;
  }
}
